import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'router';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SupportReportTableRow from 'components/SupportReportTable/container/SupportReportTableRow';

// CSS
import styles from './SupportReportTable.module.scss';

class SupportReportTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`defaultTable ${styles.table}`}>
        <Table hover>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Created</th>
              <th style={{ width: '10%' }}>User</th>
              <th style={{ width: '20%' }}>Problem</th>
              <th style={{ width: '25%' }}>Solution</th>
              <th style={{ width: '10%' }} />
              <th style={{ width: '5%' }} />
            </tr>
          </thead>
          <tbody>
            { !this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="8" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            { this.props.fetched && this.props.supportReportArr.length === 0 &&
              <tr>
                <td colSpan="8" className="emptyRowMessage">No Reports</td>
              </tr>
            }
            { this.props.fetched && this.props.supportReportArr.length > 0 &&
              this.props.supportReportArr.map((supportReport) =>
                <SupportReportTableRow
                  key={supportReport.id}
                  supportReport={supportReport}
                />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

SupportReportTable.propTypes = {
  supportReportArr: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default SupportReportTable;
