import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'material-ui/DatePicker';
import FlatButton from 'material-ui/FlatButton';

import store from '../../../store';

// API
import * as Getters from 'api/Getters';
import { canada as CanadaHourCycle, us as USHourCycle } from 'api/Lists/HourCycle';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ResetDataModal from 'components/UsersTable/container/ResetDataModal';
import SendELDIssuePDFModal from 'components/UsersTable/container/SendELDIssuePDFModal';

// Styles
import styles from './ChooseDateModal.module.scss';

class ChooseDateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Choose Date
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DatePicker
            hintText="Click to choose date"
            value={this.state.date}
            onChange={(event, date) => {
              console.log(date);
              this.setState({
                date,
              });
            }}
          />
          <br />
          <FlatButton label="Cancel" onClick={this.props.handleCloseModal} />
          <FlatButton label="Reset" primary disabled={!this.state.date} onClick={() => this.props.handleChooseDate(this.state.date)} />
        </Modal.Body>
      </Modal>
    );
  }

}

ChooseDateModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  handleChooseDate: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default ChooseDateModal;
