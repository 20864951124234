import store from 'store';
import history from 'sbHistory';
import * as Parse from 'api/Parse';
import * as Helpers from 'api/Helpers';
import * as ActionConstants from './ActionConstants';
import { clearState, fetchAndLoadUserGlobalsForState } from './Main';

function loggingIn() {
  return {
    type: ActionConstants.LOGIN_INPROGRESS,
  };
}

function loginSuccess() {
  return {
    type: ActionConstants.LOGIN_SUCCESS,
  };
}

function loginError(error) {
  return {
    type: ActionConstants.LOGIN_ERROR,
    error,
  };
}

function logoutSuccess() {
  return {
    type: ActionConstants.LOGGED_OUT,
  };
}

export function tryLogin(values) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(loggingIn());
    Parse.login(values.email, values.password).then(
      user => {
        resolve(user);
        store.dispatch(loginSuccess());
      },
      error => {
        reject(error);
        store.dispatch(loginError(error));
      }
    );
  });
  return promise;
}

export function doLogout() {
  history.push('/login');
  const promise = new Promise((resolve) => {
    // unsubscribeNotificationsForState()(dispatch);
    Parse.logout().then(
      () => {
        resolve();
        store.dispatch(clearState());
        store.dispatch(logoutSuccess());
      }
    );
  });
  return promise;
}

export function requireAuth() {
  // If there is no user logged in, refer to login page
  const parseUser = Parse.getCurrentUser();

  if (!parseUser || !parseUser.authenticated() || (!parseUser.get('isSuperUser') && !parseUser.get('allowInternalToolUsage'))) {
    doLogout();
    history.push('/login');
  } else {
    parseUser.fetch().then((parseUserObj) => {
      console.log(parseUserObj);
    }).catch((error) => {
      if (error.code === 209) {
        doLogout();
        history.push('/login');
      }
    })
  }
}
