import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Button } from 'react-bootstrap';
import Notifications from '../container/Notifications';
// Assets
const whiteLogo = require('./white-logo.png');
// CSS
import styles from './UserHeaderBar.module.scss';

function UserHeaderBar(props) {
  return (
    <table className={styles.userHeaderBar}>
      <tbody><tr>
        <td><img height="30em" role="presentation" src={whiteLogo} /></td>
        <td>Support Tool</td>
      </tr></tbody>
    </table>
  );
}

UserHeaderBar.propTypes = {
  initialize: PropTypes.func.isRequired,
};

export default UserHeaderBar;
