import PropTypes from 'prop-types';
import React from 'react';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
// CSS
import styles from './DeleteXButton.module.scss';

class DeleteXButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { confirmDeleteModalShow: false };
    this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
    this.closeConfirmDeleteModal = this.closeConfirmDeleteModal.bind(this);
    this.handleModalChoice = this.handleModalChoice.bind(this);
  }

  openConfirmDeleteModal() {
    this.state.confirmDeleteModalShow = true;
    this.setState(this.state);
  }
  closeConfirmDeleteModal() {
    this.state.confirmDeleteModalShow = false;
    this.setState(this.state);
  }

  handleModalChoice(choiceBool) {
    this.state.confirmDeleteModalShow = false;
    this.setState(this.state);
    setTimeout(() => {
      this.props.handleChoice(choiceBool);
    }, 275); // To allow bootstrap animation to finish before element gets unmounted
  }

  render() {
    return (
      <div className={styles.deleteButton} onClick={this.openConfirmDeleteModal}>
        <span className="glyphicon glyphicon-remove" />
        <ConfirmModal show={this.state.confirmDeleteModalShow} handleModalChoice={this.handleModalChoice} handleClose={this.closeConfirmDeleteModal} />
      </div>
    );
  }

}

DeleteXButton.propTypes = {
  handleChoice: PropTypes.func.isRequired,
};

export default DeleteXButton;
