import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import store from '../../store';

// API
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';

class ScriptsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
    };
    this.runScript = this.runScript.bind(this);
  }

  runScript(pageSkipInt) {
    // sourceELDDailyCertificationId, destinationCompanyId, destinationCompanyName, destinationDriverId, destinationVehicleId, destinationVehicleUnitId, username, password

    // Setters.cloneDailyCertificationEventsLocation('oibkqqQTjR', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('h3lWUViG4y', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('oDiLKN9RgZ', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('AWe5M1qMg4', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('07KG4ExDZ9', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('YtuETvbWzw', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('syq30JXhG8', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.cloneDailyCertificationEventsLocation('dSPWwoL3aM', 'X2xTsHNmgm', 'IFTA Demo', 'jPBuED01uS', 'rRzzLYPdh9', '211', 'iftasm2399', 'othertruck');
    // Setters.setAllEventsAndLocationsToACL(['Nn3V5OjwSa', 'msrL4LpLag', '9VIm8aJ1MF', '7e3YBZoF9D', 'WzThdooCRN', 'faBNhU7dl8', 'S7aMZs8Iq0', 'C7gXIRRhjk'], 'X2xTsHNmgm').then(() => {
    //   console.log('done');
    // });
    // Parse.Cloud.run('_internal:migrateHosViolation', { masterKey: store.getState().Main.parseMasterKey }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Parse.Cloud.run('localReverseGeocode', { coordinatesArray: [[39.275737, -103.710042]] }).then((asdf) => {
    //   console.log(asdf[0]);
    // });

    // Ancor: UXxMFrCxfI
    // Jagged Edge: 5grqFG9I0d
    // Centurion: uzOiAlgoGk
    // const pageSkip = pageSkipInt || 240;
    // console.log(pageSkip);
    // Parse.Cloud.run('_internal:queryAndSetFlags', { masterKey: store.getState().Main.parseMasterKey, companyId: 'uzOiAlgoGk', limit: 250, pageSkip }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    //   this.runScript(pageSkip + 1);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:checkVehicleLocationsFlags', { masterKey: store.getState().Main.parseMasterKey, companyId: 'viPPwTmcFP' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:clearIFTAVehicleStateProvinceMileage', { masterKey: store.getState().Main.parseMasterKey, companyId: 'VktSt2ocCL' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    Getters.getSubscriptionsWithIfta().then((subscriptionArr) => {
      const filterArr = ['UXxMFrCxfI', 'uzOiAlgoGk', '5grqFG9I0d', 'c3Az9UopZx', 'PahhOPZ8ll', 'BDsFQ4eEMw', 'SmCxZf7LEI', 'VBpUlQIohB', 'mMbZoj11Kt', 'LHILpIlxNv', 'qwS86UjM6B', 'AkSjgBxQ0g', 'ienuZQoRaB', 'B70ltFyAFF'];
      // console.log(subscriptionArr.map((subscriptionObj) => subscriptionObj.get('company').id));
      const companyIdArr = subscriptionArr.filter((subscriptionObj) => subscriptionObj.get('company') && filterArr.indexOf(subscriptionObj.get('company').id) === -1).map((subscriptionObj) => subscriptionObj.get('company').id);
      Parse.Cloud.run('_internal:loopQueryAndCheckIFTAVehicleStateProvinceMileages', {
        masterKey: store.getState().Main.parseMasterKey,
        fromDate: moment('2017-12-01').toDate(),
        toDate: moment('2018-02-15').toDate(),
        companyIdArray: companyIdArr,
      }).then((asdf) => {
        console.log('done');
        console.log(asdf);
      }, (error) => {
        console.log('error');
        console.log(error);
      });
    });
    // Parse.Cloud.run('_internal:loopQueryAndCheckIFTAVehicleStateProvinceMileages', {
    //   masterKey: store.getState().Main.parseMasterKey,
    //   // vehicleId: 'bcdAVOtfhc',
    //   // companyIdArray: ['5grqFG9I0d', 'c3Az9UopZx', 'PahhOPZ8ll', 'BDsFQ4eEMw', 'SmCxZf7LEI', 'VBpUlQIohB', 'mMbZoj11Kt', 'LHILpIlxNv', 'qwS86UjM6B', 'AkSjgBxQ0g', 'ienuZQoRaB', 'B70ltFyAFF'],
    //   fromDate: moment('2017-12-01').toDate(),
    //   toDate: moment('2018-02-15').toDate(),
    //   companyId: 'FE79SxMQD3',
    //   // companyIdArray: ['UXxMFrCxfI', 'uzOiAlgoGk', '5grqFG9I0d', 'c3Az9UopZx', 'PahhOPZ8ll', 'BDsFQ4eEMw', 'SmCxZf7LEI', 'VBpUlQIohB', 'mMbZoj11Kt', 'LHILpIlxNv', 'qwS86UjM6B', 'AkSjgBxQ0g', 'ienuZQoRaB', 'B70ltFyAFF'],
    //   // companyIdArray: ['UXxMFrCxfI', 'uzOiAlgoGk', '5grqFG9I0d', 'c3Az9UopZx', 'PahhOPZ8ll', 'BDsFQ4eEMw', 'SmCxZf7LEI', 'VBpUlQIohB', 'mMbZoj11Kt', 'LHILpIlxNv', 'qwS86UjM6B', 'AkSjgBxQ0g', 'ienuZQoRaB', 'B70ltFyAFF'],
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:cleanIFTAVehicleStateProvinceMileages', {
    //   masterKey: store.getState().Main.parseMasterKey,
    //   companyIdArray: ['gDvY6echbB'],
    //   // vehicleId: 'C0pE6i2Woh',
    //   // companyId: 'uzOiAlgoGk'
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:queryGeopoints', { masterKey: store.getState().Main.parseMasterKey, lat: 22.674606, lng: 113.887298 }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:setELDEventsToAOBRDEnabled', { masterKey: store.getState().Main.parseMasterKey, driverId: 'DAdl3bj2kx' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:fixCompanySoftwareVersion', { masterKey: store.getState().Main.parseMasterKey, version: '0.66' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });
    // Parse.Cloud.run('_internal:lowercaseSearchTerms', {
    //   masterKey: store.getState().Main.parseMasterKey,
    //   className: 'Trailer',
    //   propertyName: 'unitId',
    //   lowercasePropertyName: 'unitId_lowercase',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });
    // Parse.Cloud.run('_internal:setDriverYardMoveAndPersonal', { masterKey: store.getState().Main.parseMasterKey }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });
    // console.log(Parse);
    // Parse.Cloud.run('_internal:hideLogsBeforeDate', { masterKey: store.getState().Main.parseMasterKey, companyId: 'K7oPdtIAcu', startDate: moment('2017-12-01').startOf('day').toDate() }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Parse.Cloud.run('_internal:getPrefix', { masterKey: store.getState().Main.parseMasterKey, companyName: this.state.companyName }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });
  }

  render() {
    return (
      <div>
        <Title title="Scripts" />
        <div>
          <input
            value={this.state.input}
            onChange={(e) => this.setState({ ...this.state, input: e.target.value })}
          />
          <button className="buttonDefault" onClick={() => this.runScript()}>Run Script</button>
        </div>
      </div>
    );
  }
}

export default ScriptsLayout;
