import * as ActionConstants from './ActionConstants';
import history from 'sbHistory';
import * as Parse from 'api/Parse';
import store from 'store';

import * as Getters from '../api/Getters';

export function fetchingUsers() {
  return {
    type: ActionConstants.FETCH_USERS_INPROGRESS,
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: ActionConstants.FETCH_USERS_SUCCESS,
    users,
  };
}

export function fetchUsersError(error) {
  return {
    type: ActionConstants.FETCH_USERS_ERROR,
    error,
  };
}

export function deleteUsers() {
  return {
    type: ActionConstants.DELETE_USERS,
  };
}

export function registerUser() {
  return {
    type: ActionConstants.REGISTER_USER_INPROGRESS,
  };
}

export function registerSuccess(user) {
  return {
    type: ActionConstants.REGISTER_USER_SUCCESS,
    user,
  };
}

export function registerError(error) {
  return {
    type: ActionConstants.REGISTER_USER_ERROR,
    error,
  };
}

export function fetchUsersForState(value, property) {
  store.dispatch(fetchingUsers());
  Getters.getUsersByProperty(value, property).then(
    (userParseObjArr) => {
      store.dispatch(fetchUsersSuccess(userParseObjArr));
    },
    (err) => {
      store.dispatch(fetchUsersError(err));
    }
  );
}

export function deleteUsersForState() {
  store.dispatch(deleteUsers());
}

export function registerAdmin(registerValuesObject) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(registerUser());
    if (Parse.getCurrentUser()) {
      Parse.logout();
    }
    Parse.registerAdminToParse(registerValuesObject).then(
      parseUser => {
        Parse.login(registerValuesObject.emailAddress, registerValuesObject.password).then(
          user => {
            history.push('/documents');
            resolve(user);
            store.dispatch(registerSuccess(parseUser));
          },
          error => {
            reject(error);
            store.dispatch(registerError(error));
          }
        );
      },
      error => {
        reject(error);
        store.dispatch(registerError(error));
      }
    );
  });
  return promise;
}
