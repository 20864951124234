import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { browserHistory } from 'router';

import store from '../../../store';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import { canada as CanadaHourCycle, us as USHourCycle } from 'api/Lists/HourCycle';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ResetDataModal from 'components/UsersTable/container/ResetDataModal';
import ResetBypassModal from 'components/UsersTable/container/ResetBypassModal';
import ChooseDateModal from 'components/ChooseDateModal/container/ChooseDateModal';
import SendELDIssuePDFModal from 'components/UsersTable/container/SendELDIssuePDFModal';
import RestartSoftwareModal from 'components/RestartSoftwareModal/container/RestartSoftwareModal';
import ToggleSimModal from 'components/UsersTable/container/ToggleSimModal';
import ELDDailyCertificationsModal from 'components/UsersTable/container/ELDDailyCertificationsModal';

// Styles
import styles from './DriverDetailsModal.module.scss';

class DriverDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChooseDateModal: false,
      showRestartSoftwareModal: false,
      showToggleSimModal: false,
      showResetBypassModal: false,
      showELDDailyCertificationsModal: false,
      allowDisableEldDailyCert: false,
      disableUpdateButton: false,
      date: moment(),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show) {
      this.setState({ ...this.state, loading: true, simUsageRecord: null }, () => {
        Getters.getDriverByUserId(nextProps.user.id).then((driverArr) => {
          if (driverArr && driverArr.length > 0) {
            const newState = { ...this.state };
            const driver = driverArr[0];
            const user = driverArr[0].get('user');
            newState.driver = driver;
            newState.user = user;
            this.setState({ ...newState, loading: false }, () => {
              if (driver.get('deviceIMEI')) {
                Getters.getSimByImei(driver.get('deviceIMEI')).then((sim) => {
                  this.setState({ ...this.state, simIccid: sim ? sim.get('iccid') : null });
                  if (sim) {
                    Getters.getSimUsageRecordsForMonth(this.state.date.toDate(), sim.get('iccid')).then((simUsageRecord) => {
                      this.setState({ ...this.state, simIccid: sim ? sim.get('iccid') : null, simUsageRecord });
                    });
                  }
                });
              }
            });

            Getters.checkSupportDisableEldDailyCert(driver.get('belongsToCompany') && driver.get('belongsToCompany').id).then((allowDisableEldDailyCert) => {
              this.setState({ ...this.state, allowDisableEldDailyCert });
            });
          }
        });
      });
    }
  }

  render() {
    const totalUsageMb = this.state.simUsageRecord && this.state.simUsageRecord.data && (Math.round(this.state.simUsageRecord.data.total / 10000) / 100);

    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.user &&
              `${this.props.user.get('firstName')} ${this.props.user.get('lastName')}`
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.loading &&
            <LoadingIcon />
          }
          {!this.state.loading &&
            <div>
              Phone Number:
              <br />
              {this.props.user &&
                <div>
                  <b>{this.props.user.get('phoneNumber')}</b>
                </div>
              }
              <br />
              <br />
              Software Version:
              <br />
              {this.state.driver && this.state.driver.get('currentELDVersion') && <b>{this.state.driver.get('currentELDVersion')}</b>}
              {this.state.driver && this.state.driver.get('currentELDVersion') && this.state.driver.get('currentELDVersion') <= 1.23 &&
                <div className={styles.error}>
                  Driver is on an older version of Switchboard. Advise updating.
                </div>
              }
              {this.state.driver && this.state.driver.get('deviceIMEI') &&
                (
                  <div>
                    <button
                      className="buttonDefault"
                      disabled={this.state.disableUpdateButton}
                      onClick={async () => {
                        this.setState({ ...this.state, updateError: null, disableUpdateButton: true });
                        try {
                          await Setters.publishUpdateForScalefusionDevice(this.state.driver.get('deviceIMEI'));
                        } catch (e) {
                          console.log(e);
                          this.setState({ ...this.state, updateError: e.message });
                        }
                        this.setState({ ...this.state, disableUpdateButton: false });
                      }}
                    >
                      Prompt Update
                    </button>
                  </div>
                )
              }
              <div className="errorText">{this.state.updateError}</div>
              <br />
              <br />
              ELD Engine Module Version:
              <br />
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <b>{this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion')}</b>
              }
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion') < this.state.driver.get('vehicle').get('eldHardware').get('targetSoftwareVersion') &&
                this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion') !== 0 &&
                <div className={styles.error}>
                  Driver is on an older version of ELD Engine Module. Advise updating.
                </div>
              }
              <br />
              <br />
              IMEI:
              <br />
              {this.state.driver && this.state.driver.get('deviceIMEI') && <b>{this.state.driver.get('deviceIMEI')}</b>}
              {this.state.driver && !this.state.driver.get('deviceIMEI') && <b>No IMEI Found</b>}
              <br /><br />
              ICCID:
              <br />
              {this.state.driver && this.state.simIccid && <b>{this.state.simIccid}</b>}
              {this.state.driver && !this.state.simIccid && <b>No ICCID Found</b>}
              <br />
              <br />
              {this.state.simUsageRecord &&
                <div className={this.state.error ? styles.error : ''}>
                  SIM Data Usage ({moment(this.state.simUsageRecord.period.start).format('MMMM DD, YYYY')} - {moment(this.state.simUsageRecord.period.end).format('MMMM DD, YYYY')}):
                  <br />
                  <b>Usage (MB): </b><span className={totalUsageMb && totalUsageMb > 750 ? styles.error : ''}>{totalUsageMb}</span>
                  <br />
                </div>
              }
              {this.state.driver && !this.state.driver.get('currentELDVersion') && <b>No Current ELD Version Found</b>}
              <br />
              Vehicle:
              <br />
              {this.state.driver && !this.state.driver.get('vehicle') && <b>No Vehicle Found</b>}
              {this.state.driver && this.state.driver.get('vehicle') && <b>{this.state.driver.get('vehicle').get('unitId')}</b>}
              <br />
              <br />
              Compliance Mode:
              <br />
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <b>
                  {this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'AOBRD Mode'}
                  {!this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'ELD Mode'}
                </b>
              }
              <br />
              <br />
              Canada HOS Cycle:
              {this.state.driver &&
                <div>
                  <b>{CanadaHourCycle[this.state.driver.get('hourCycleCanadaInt')]}</b>
                  {this.state.driver && this.state.driver.get('hourCycleUSAInt') && this.state.driver.get('hourCycleUSAInt') !== 1 &&
                    <div className={styles.error}>
                      May be incorrect cycle time. Advise changing
                    </div>
                  }
                </div>
              }
              <br />
              US HOS Cycle:
              {this.state.driver &&
                <div>
                  <b>{USHourCycle[this.state.driver.get('hourCycleUSAInt')]}</b>
                  {this.state.driver && this.state.driver.get('hourCycleUSAInt') && this.state.driver.get('hourCycleUSAInt') !== 1 &&
                    <div className={styles.error}>
                      May be incorrect cycle time. Advise changing
                    </div>
                  }
                </div>
              }
              <br />
              Quick Actions:
              <br />

              <button className="buttonDefault" onClick={() => this.props.showResetPasswordModal(this.props.user)}>Reset Password</button>

              {this.state.simIccid &&
                <span style={{ marginLeft: '1em' }}>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showResetDataModal: true })}>Reset Data</button>
                  <ResetDataModal
                    show={this.state.showResetDataModal}
                    simIccid={this.state.simIccid}
                    handleClose={() => this.setState({ ...this.state, showResetDataModal: false })}
                  />
                </span>
              }

              <br />
              <br />
              {this.state.driver && this.state.allowDisableEldDailyCert &&
                <span>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showELDDailyCertificationsModal: true })}>View ELD Daily Certifications</button>
                  <ELDDailyCertificationsModal
                    show={this.state.showELDDailyCertificationsModal}
                    driver={this.state.driver}
                    handleCloseModal={() => this.setState({ ...this.state, showELDDailyCertificationsModal: false })}
                  />
                </span>
              }
              <br />
              <br />
              {this.state.driver &&
                <span>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showChooseDateModal: true })}>Reset Autogenerated Driving</button>
                  <ChooseDateModal
                    show={this.state.showChooseDateModal}
                    handleChooseDate={(date) => {
                      Setters.setAutogeneratedForDriver(this.state.driver.id);
                      // Setters.setELDEventsToAOBRD(this.state.driver.id, date);
                      this.setState({ ...this.state, showChooseDateModal: false });
                    }}
                    handleCloseModal={() => this.setState({ ...this.state, showChooseDateModal: false })}
                  />
                </span>
              }

              <br />
              <br />

              {this.state.driver &&
                <div>
                  <button
                    className="buttonDefault"
                    onClick={() =>
                      browserHistory.push('/ticket')
                    }
                  >
                    Generate Defective ELD PDF
                  </button>
                  {/* <SendELDIssuePDFModal
                    show={this.state.showSendELDIssuePDFModal}
                    driver={this.state.driver}
                    handleClose={() => this.setState({ ...this.state, showSendELDIssuePDFModal: false })}
                  /> */}
                </div>
              }

              <br />
              <br />

              {this.state.driver && this.state.driver.get('user') &&
                <div>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showRestartSoftwareModal: true })}>Send !PLM!</button>
                  <RestartSoftwareModal
                    show={this.state.showRestartSoftwareModal}
                    user={this.state.driver.get('user')}
                    handleClose={() => this.setState({ ...this.state, showRestartSoftwareModal: false })}
                  />
                </div>
              }

              <br /><br />

              {this.state.driver && this.state.driver.get('user') &&
                <div>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showToggleSimModal: true })}>Activate/Deactivate SIM</button>
                  <ToggleSimModal
                    show={this.state.showToggleSimModal}
                    user={this.state.driver.get('user')}
                    handleClose={() => this.setState({ ...this.state, showToggleSimModal: false })}
                  />
                </div>
              }

              {this.state.driver &&
                this.state.driver.get('vehicle') &&
                this.state.driver.get('vehicle').get('weighStationBypassVehicle') &&
                this.state.driver.get('vehicle').get('weighStationBypassVehicle').get('enabled') &&
                this.state.driver.get('vehicle').get('weighStationBypassVehicle').get('isSubscriptionProduction') &&
                <div>
                  <br /><br />
                  <button
                    className="buttonDefault"
                    onClick={() => this.setState({ ...this.state, showResetBypassModal: true })}
                  >
                    Reset Weigh Station Bypass
                  </button>
                  <ResetBypassModal
                    show={this.state.showResetBypassModal}
                    vehicleObj={this.state.driver.get('vehicle')}
                    handleClose={() => this.setState({ ...this.state, showResetBypassModal: false })}
                  />
                </div>
              }
            </div>
          }
        </Modal.Body>
      </Modal>
    );
  }

}

DriverDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
  showResetPasswordModal: PropTypes.func.isRequired,
};

export default DriverDetailsModal;
