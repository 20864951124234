import React from 'react';
import { connect } from 'react-redux';

// Components
import Title from 'components/LayoutTitle/view/Title';

// CSS
import styles from './HomeLayout.module.scss';

class CompaniesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className={styles.companiesLayout}>
        Welcome to Switchboard Support!
      </div>
    );
  }
}


export default CompaniesLayout;
