import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'sbHistory';

// Actions
import { initialize } from 'actions/Main';
// Components
import FeatureHeaderBar from '../view/FeatureHeaderBar';
import UserHeaderBar from '../view/UserHeaderBar';

// CSS
import styles from './Header.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabStates: {
        ticket: true,
        users: true,
        supportReport: true,
        sims: true,
        register: true,
        equipment: true
      },
    }
    this.changeRoute = this.changeRoute.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { User } = nextProps;

    if (User && User.user) {
      this.refreshState(User.user);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.User.fetched !== this.props.User.fetched) {
      return true;
    }
    if (nextProps.pageLocation !== this.props.pageLocation) {
      return true;
    }
    return false;
  }

  changeRoute(route) {
    history.push(`/${route}`);
  }

  refreshState(user) {
    const newState = { ...this.state };
    const userSpecialPermission = user && user.id && user.get('userSpecialPermission'); // need id check for initial log-in (default is {} not undefined)


    if (userSpecialPermission) {
      const disableSuperUserTicketModule = userSpecialPermission.get('disableSuperUserTicketModule');
      const disableSuperUserUsersModule = userSpecialPermission.get('disableSuperUserUsersModule');
      const disableSuperUserSupportReportModule = userSpecialPermission.get('disableSuperUserSupportReportModule');
      const disableSuperUserSimsModule = userSpecialPermission.get('disableSuperUserSimsModule');
      const disableSuperUserRegisterModule = userSpecialPermission.get('disableSuperUserRegisterModule');
      const disableSuperUserEquipmentModule = userSpecialPermission.get('disableSuperUserEquipmentModule');

      if (disableSuperUserTicketModule) {
        newState.tabStates.ticket = false;
      }
      if (disableSuperUserUsersModule) {
        newState.tabStates.users = false;
      }
      if (disableSuperUserSupportReportModule) {
        newState.tabStates.supportReport = false;
      }
      if (disableSuperUserSimsModule) {
        newState.tabStates.sims = false;
      }
      if (disableSuperUserRegisterModule) {
        newState.tabStates.register = false;
      }
      if (disableSuperUserEquipmentModule) {
        newState.tabStates.equipment = false;
      }
    }

    this.setState(newState);
  }

  render() {
    const { tabStates } = this.state;
    return (
      <div className={styles.header}>
        <UserHeaderBar initialize={initialize} />
        <FeatureHeaderBar
          changeRoute={this.changeRoute}
          pageLocation={this.props.pageLocation.pathname}
          tabStates={tabStates}
        />
      </div>
    );
  }
}

Header.propTypes = {
  User: PropTypes.object.isRequired,
  pageLocation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    User,
  };
};

export default connect(mapStateToProps)(Header);
