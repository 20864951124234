import Parse from 'parse';
import Promise from 'promise';
import store from '../store';

function getSimState(iccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:getSimStateByIccid', {
      masterKey: store.getState().Main.parseMasterKey,
      iccid,
    }).then((status) => {
      resolve(status);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function getSimInfo(iccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:getSimInfoByIccid', {
      masterKey: store.getState().Main.parseMasterKey,
      iccid,
    }).then((infoObj) => {
      resolve(infoObj || {});
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function toggleSimState(imei, isActive) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:toggleSimStateByImei', {
      masterKey: store.getState().Main.parseMasterKey,
      imei,
      isActive,
    }).then((confirm) => {
      resolve(confirm);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function getSimSessionInfo(iccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:getSimSessionInfo', {
      masterKey: store.getState().Main.parseMasterKey,
      iccid,
    }).then((status) => {
      resolve(status);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

export {
  getSimState,
  getSimInfo,
  toggleSimState,
  getSimSessionInfo,
};
