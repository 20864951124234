import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// Actions

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import SimsTable from 'components/SimsTable/container/SimsTable';

// CSS
import styles from './SimsLayout.module.scss';

class SimsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      loading: false,
      simArr: [], // holds companies (all or active) based on filter dropdown view
      showUploadSimFileModal: false,
    };
    this.handleEntry = this.handleEntry.bind(this);
  }

  handleEntry(valueType, value) {
    this.setState({ ...this.state, loading: true }, () => {
      Getters.getSimArr(valueType, value).then((simArr) => {
        const filteredSimArr = [];
        const iccidMap = {};
        for (let i = 0; i < simArr.length; i++) {
          const simObj = simArr[i];
          if (simObj.get('iccid') && !iccidMap[simObj.get('iccid')]) {
            iccidMap[simObj.get('iccid')] = true;
            filteredSimArr.push(simObj);
          }
        }
        this.setState({ ...this.state, loading: false, simArr: filteredSimArr.sort((a, b) => a.get('iccid') - b.get('iccid')) });
      });
    });
  }

  render() {
    return (
      <div className={styles.SimsLayout}>
        <Title title="Sim">
          <span className="inlineBlock">
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('companyName', e.target.value)} placeholder="Enter Company" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('imei', e.target.value)} placeholder="Enter IMEI #" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('iccid', e.target.value)} placeholder="Enter Sim #" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('trackerEsn', e.target.value)} placeholder="Enter ESN #" />
          </span>
        </Title>
        <SimsTable
          loading={this.state.loading} // pass it Company state to get action state
          simArr={this.state.simArr}
          date={this.state.date}
        />
      </div>
    );
  }
}

SimsLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

export default SimsLayout;
