import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import OfficeUserDetailsModal from 'components/UsersTable/container/OfficeUserDetailsModal';
import SendUnableToReachEmailModal from 'components/UsersTable/container/SendUnableToReachEmailModal';
import DriverDetailsModal from 'components/UsersTable/container/DriverDetailsModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import UserTableRow from 'components/UsersTable/container/UserTableRow';
import ResetPasswordModal from './ResetPasswordModal';

// CSS
import styles from './UsersTable.module.scss';

class UsersTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      logInInProgress: false,
      showResetPasswordModal: false,
      showSendUnableToReachEmailModal: {
        show: false,
        email: '',
        name: '',
      },
      showResetDataModal: false,
      showOfficeUserDetailsModal: false,
      showDriverDetailsModal: false,
      creatingSupportReport: false,
    };
    this.nameSort = this.nameSort.bind(this);
    this.logInAsCompany = this.logInAsCompany.bind(this);
    this.showResetPasswordModal = this.showResetPasswordModal.bind(this);
    this.hideResetPasswordModal = this.hideResetPasswordModal.bind(this);
    this.showResetDataModal = this.showResetDataModal.bind(this);
    this.hideResetDataModal = this.hideResetDataModal.bind(this);
    this.showDriverDetailsModal = this.showDriverDetailsModal.bind(this);
    this.hideDriverDetailsModal = this.hideDriverDetailsModal.bind(this);
    this.showOfficeUserDetailsModal = this.showOfficeUserDetailsModal.bind(this);
    this.hideOfficeUserDetailsModal = this.hideOfficeUserDetailsModal.bind(this);
    this.createSupportReport = this.createSupportReport.bind(this);
  }

  showDriverDetailsModal(user) {
    this.setState({ ...this.state, driverDetailsUser: user, showDriverDetailsModal: true });
  }

  hideDriverDetailsModal() {
    this.setState({ ...this.state, driverDetailsUser: undefined, showDriverDetailsModal: false });
  }

  showOfficeUserDetailsModal(user) {
    this.setState({ ...this.state, selectedUser: user, showOfficeUserDetailsModal: true });
  }

  hideOfficeUserDetailsModal() {
    this.setState({ ...this.state, selectedUser: undefined, showOfficeUserDetailsModal: false });
  }

  showResetPasswordModal(user) {
    this.setState({ ...this.state, editUser: user, showResetPasswordModal: true });
  }

  hideResetPasswordModal() {
    this.setState({ ...this.state, editUser: undefined, showResetPasswordModal: false });
  }

  showResetDataModal(user) {
    this.setState({ ...this.state, editUser: user, showResetDataModal: true });
  }

  hideResetDataModal() {
    this.setState({ ...this.state, editUser: undefined, showResetDataModal: false });
  }

  logInAsCompany(userId) {
    this.setState({ ...this.state, logInInProgress: true }, () => {
      Promise.all([
        Getters.getSessionToken(userId),
      ]).then((results) => {
        const token = results[0];
        this.setState({ ...this.state, logInInProgress: false });
        console.log(Parse.getCurrentUser() && Parse.getCurrentUser().id);
        console.log(`https://app-support.onswitchboard.com/login?auth=${token}&user=${Parse.getCurrentUser() && Parse.getCurrentUser().id}&id=${userId}`);
        window.open(`https://app-support.onswitchboard.com/login?auth=${token}&user=${Parse.getCurrentUser() && Parse.getCurrentUser().id}&id=${userId}`, '_blank');
      }, (error) => {
        this.setState({ ...this.state, logInInProgress: false });
        console.log('error');
        console.log(error);
      });
    });
  }

  nameSort(user, nextUser) {
    const comparatorA = user.name.toLowerCase();
    const comparatorB = nextUser.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  createSupportReport(userObj) {
    this.setState({ ...this.state, creatingSupportReport: true });
    Setters.createSupportReport(userObj).then((supportReportObj) => {
      history.push({ pathname: 'supportReport', query: { companyId: supportReportObj.get('company').id } });
    });
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Company</th>
              <th />
              {
                // <th>Admin</th>
                // <th>Number of Users</th>
                // <th>Number of JobLinks</th>
              }
              <th />
            </tr>
          </thead>
          <tbody>
            {!this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {this.props.fetched && this.props.users.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Users to Display</td>
              </tr>
            }
            {this.props.fetched && this.props.users.length > 0 &&
              this.props.users.map((user) => {
                let resellerCompanyName = '';
                if (user && user.get('belongsToCompany') && user.get('belongsToCompany').get('promoCode')) {
                  for (let i = 0; i < this.props.resellerCompanyArr.length; i++) {
                    const resellerCompanyObj = this.props.resellerCompanyArr[i];
                    if (resellerCompanyObj.get('promoCode') === user.get('belongsToCompany').get('promoCode')) {
                      resellerCompanyName = resellerCompanyObj.get('name');
                    }
                  }
                }
                if (user.get('userType') && user.get('belongsToCompany')) {
                  return (
                    <UserTableRow
                      key={user.id}
                      user={user}
                      resellerCompanyName={resellerCompanyName}
                      logInInProgress={this.state.logInInProgress}
                      logInAsCompany={this.logInAsCompany}
                      showDriverDetailsModal={this.showDriverDetailsModal}
                      showOfficeUserDetailsModal={this.showOfficeUserDetailsModal}
                      showSendUnableToReachEmailModal={(email, name) => this.setState({ ...this.state, showSendUnableToReachEmailModal: { show: true, email, name } })}
                      createSupportReport={this.createSupportReport}
                      creatingSupportReport={this.state.creatingSupportReport}
                    />
                  );
                }
              })
            }
          </tbody>
        </Table>
        {this.state.editUser &&
          <ResetPasswordModal
            user={this.state.editUser}
            hideModal={this.hideResetPasswordModal}
            show={this.state.showResetPasswordModal}
          />
        }
        <DriverDetailsModal
          user={this.state.driverDetailsUser}
          hideModal={this.hideDriverDetailsModal}
          show={this.state.showDriverDetailsModal}
          showResetPasswordModal={this.showResetPasswordModal}
        />
        <SendUnableToReachEmailModal
          show={this.state.showSendUnableToReachEmailModal.show}
          email={this.state.showSendUnableToReachEmailModal.email}
          name={this.state.showSendUnableToReachEmailModal.name}
          handleClose={() => this.setState({ ...this.state, showSendUnableToReachEmailModal: { show: false, email: '', name: '' } })}
        />
        <OfficeUserDetailsModal
          user={this.state.selectedUser}
          hideModal={this.hideOfficeUserDetailsModal}
          show={this.state.showOfficeUserDetailsModal}
          showResetPasswordModal={this.showResetPasswordModal}
          showChangeEmailModal={this.showChangeEmailModal}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  resellerCompanyArr: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default UsersTable;
