import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';
import * as Sim from 'api/Sim';
import EldStatusInt from 'api/lists/EldStatusInt';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import styles from './SimRow.module.scss';

class SimRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // date: moment(),
      usageRecord: undefined,
      loading: false,
    };

    // this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    if (this.props.simObj.get('imei')) {
      Getters.getDriverByIMEI(this.props.simObj.get('imei')).then((driverObj) => {
        this.setState({ ...this.state, driver: driverObj });
      });
    }

    if (this.props.simObj.get('iccid')) {
      this.setState({ ...this.state, error: null, loading: true });
      Promise.all([
        Sim.getSimInfo(this.props.simObj.get('iccid')),
        Getters.getSimUsageRecordsForMonth(moment(this.props.date).toDate(), this.props.simObj.get('iccid')),
      ]).then((responses) => {
        const infoObj = responses[0];
        const usageRecord = responses[1];
        this.setState({ ...this.state, usageRecord, loading: false, infoObj });
        const totalUsageMb = this.state.usageRecord && this.state.usageRecord.data && (Math.round(this.state.usageRecord.data.total / 10000) / 100);
        if (totalUsageMb > 0 || infoObj.status === 'active') {
          this.props.setActiveCheck(true);
        } else {
          this.props.setActiveCheck(false);
        }
      }).catch((error) => {
        this.setState({ ...this.state, loading: false, error: error?.message || error });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date) {
      this.setState({ ...this.state, error: null, loading: true });
      if (this.props.simObj.get('iccid')) {
        Getters.getSimUsageRecordsForMonth(moment(this.props.date).toDate(), this.props.simObj.get('iccid')).then((usageRecord) => {
          this.setState({ ...this.state, usageRecord, loading: false });
          const totalUsageMb = this.state.usageRecord && this.state.usageRecord.data && (Math.round(this.state.usageRecord.data.total / 10000) / 100);
          if (totalUsageMb > 0 || this.props.simObj.get('isActive')) {
            this.props.setActiveCheck(true);
          } else {
            this.props.setActiveCheck(false);
          }
        }).catch((error) => {
          this.setState({ ...this.state, loading: false, error: error?.message || error });
        });
      }
    }
  }

  // handleDateChange(date) {
  //   this.setState({ ...this.state, date: moment(date), loading: true }, () => {
  //     if (date) {
  //       Getters.getSimUsageRecordsForMonth(date.toDate(), this.props.simObj.get('iccid')).then((usageRecord) => {
  //         this.setState({ ...this.state, usageRecord, loading: false });
  //       });
  //     } else {
  //       this.setState({ ...this.state, loading: false });
  //     }
  //   });
  // }

  render() {
    const downloadMb = this.state.usageRecord && this.state.usageRecord.data && (Math.round(this.state.usageRecord.data.download / 10000) / 100);
    const uploadMb = this.state.usageRecord && this.state.usageRecord.data && (Math.round(this.state.usageRecord.data.upload / 10000) / 100);
    const totalUsageMb = this.state.usageRecord && this.state.usageRecord.data && (Math.round(this.state.usageRecord.data.total / 10000) / 100);
    return (
      <tr>
        <td>
          <b>Sim #: </b>{this.props.simObj.get('iccid')}
          <br />
          <b>IMEI #: </b>{this.props.simObj.get('imei')}
          <br />
          <b>ESN #: </b>{this.props.simObj.get('trackerEsn')}
          <br />
          <b>Status: </b>{this.state.infoObj && this.state.infoObj.status}
        </td>
        <td>{this.props.simObj.get('belongsToCompany') &&
          <div>
            <b>
              {this.props.simObj.get('belongsToCompany').get('name')} {`(companyId: ${this.props.simObj.get('belongsToCompany').id})`}
            </b>
            <br />
            {this.state.driver &&
              <div>
                <b>{Helpers.toTitleCase(this.state.driver.get('user_fullName'))}</b>
                <br />
                <b>Last ELD Event:</b> <kbd>{EldStatusInt[this.state.driver.get('eldStatusInt')]}</kbd> &nbsp;{this.state.driver.get('latestELDEvent_eventDateTime') && moment(this.state.driver.get('latestELDEvent_eventDateTime')).format('ll')}
                <br />
                <b>Software Version:</b> {this.state.driver.get('currentELDVersion')}
                {this.props.simObj.get('imei') &&
                  <button
                    className="buttonDefault"
                    disabled={this.state.loading}
                    onClick={async () => {
                      this.setState({ ...this.state, updateError: null, loading: true });
                      try {
                        await Setters.publishUpdateForScalefusionDevice(this.props.simObj.get('imei'));
                      } catch (e) {
                        this.setState({ ...this.state, updateError: e.message });
                      }
                      this.setState({ ...this.state, loading: false });
                    }}
                  >
                    Prompt Update
                  </button>
                }
                <div className="errorText">{this.state.updateError}</div>
              </div>
            }
          </div>
        }</td>
        <td>
          {/* <div className={styles.alignMiddle}>
            <DatePicker 
              className="input" 
              selected={this.state.date} 
              placeholderText="Choose Date" 
              onChange={(date) => this.handleDateChange(date)} 
            />
          </div> */}

          &nbsp;

          <div className={styles.alignMiddle}>
            {this.state.loading &&
              <div><LoadingIcon /></div>
            }
            {!this.state.loading && this.state.usageRecord &&
              <div className={this.state.error ? styles.error : ''}>
                <div>
                  Last Online:&nbsp;
                  <span className={this.state.infoObj.lastOnline && moment(this.state.infoObj.lastOnline).isAfter(moment().subtract(1, 'hour')) > 0 ? styles.active : styles.inactive}>
                    {this.state.infoObj.lastOnline && moment(this.state.infoObj.lastOnline).format('lll')}
                    {!this.state.infoObj.lastOnline && <span>Inactive</span>}
                  </span>
                </div>
                <div>
                  {moment(this.state.usageRecord.period.start).format('MMMM DD, YYYY')} - {moment(this.state.usageRecord.period.end).format('MMMM DD, YYYY')}
                </div>
                <b>Usage (MB): </b><span className={totalUsageMb && totalUsageMb > 0 ? styles.active : styles.inactive}>{totalUsageMb}</span>
              </div>
            }
          </div>
        </td>
      </tr>
    );
  }
}

SimRow.propTypes = {
  simObj: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  setActiveCheck: PropTypes.func.isRequired,
};

export default SimRow;

