import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  drivers: [], // Drivers created by the specific dispatcher
  fetched: true, // Whether or not drivers are loaded
};

export function Driver(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_DRIVERS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_DRIVERS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_DRIVERS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_DRIVERS_SUCCESS,
        drivers: action.drivers,
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_DRIVERS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_DRIVERS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_DRIVERS: {
      return initialState;
    }
    case ActionConstants.ADD_DRIVER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_DRIVER_SUCCESS: {
      // Succesfully added to db
      const updatedDrivers = state.drivers.concat([action.driver]);
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_SUCCESS,
        drivers: updatedDrivers,
        error: undefined,
      };
    }
    case ActionConstants.ADD_DRIVER_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_DRIVER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_INPROGRESS,
        updateReference: action.driver,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_DRIVER_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_SUCCESS,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_DRIVER_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_ERROR,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
