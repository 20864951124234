/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import { getPointer } from 'api/Parse';
import store from '../store';
import * as Helpers from './Helpers';

function getActiveSubscriptions() {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getActiveSubscriptions').then(
      subscriptionParseObjArr => resolve(subscriptionParseObjArr),
      error => reject(error),
    );
  });
  return promise;
}

function getSubscriptionsWithIfta() {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getSubscriptionsWithIfta').then(
      subscriptionParseObjArr => resolve(subscriptionParseObjArr),
      error => reject(error),
    );
  });
  return promise;
}

function getCompanyByName(companyName) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getCompanyFromName', { companyName }).then(
      companyParseObjArr => resolve(companyParseObjArr)
    );
  });
  return promise;
}

function getParentChildCompaniesForCompany(companyId) {
  return Parse.Cloud.run('support:getParentChildCompaniesForCompany', {
    companyId,
  });
}

function getActiveCountsForCompany(companyId) {
  return Parse.Cloud.run('support:getActiveCountsForCompany', {
    companyId,
  });
}

function getModulesForCompany(companyId) {
  return Parse.Cloud.run('support:getModulesForCompany', {
    companyId,
  });
}

function getAndRefreshSimsForCompany(companyId) {
  return Parse.Cloud.run('_internal:getAndRefreshSimsForCompany', {
    companyId,
  });
}

function checkAndGetActiveVehiclesCountObjForCompany(companyId) {
  return Parse.Cloud.run('_internal:checkAndGetActiveVehiclesCountObjForCompany', {
    companyId,
  });
}

const getCompanyById = (companyId) => {
  return Parse.Cloud.run('internal:getCompanyById', { companyId });
};

function getDriverByIMEI(imei) {
  return Parse.Cloud.run('_internal:getDriverByIMEI', { returnAsParse: true, parseClassName: 'Driver', imei }).then((driverObj) => {
    return driverObj;
  });
}

function getDriverByUserId(userId) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getDriverByUserId', { userId }).then(
      driverParseObjectArr => resolve(driverParseObjectArr),
      err => reject(err)
    );
  });
  return promise;
}

function getEldDailyCertificationsForDriver(driverId) {
  return Parse.Cloud.run('_internal:getEldDailyCertificationsForDriver', {
    driverId,
  });
}

function getOutdatedUsers(type) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getOutdatedUsers', { masterKey: store.getState().Main.parseMasterKey, type }).then(
      userParseObjArr => {
        resolve(userParseObjArr);
      },
      err => {
        console.log(err);
        reject(err);
      }
    );
  });
  return promise;
}

function getResellerCompanies(includeHiddenBool) {
  return Parse.Cloud.run('_internal:queryResellerCompany', { includeHiddenBool });
}


function getSessionToken(userId) {
  return Parse.Cloud.run('internal:getUserSessionToken', { masterKey: store.getState().Main.parseMasterKey, userId });
}

function getUsersByProperty(value, property) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getUserByProperty', { property, value }).then(
      userParseObjArr => resolve(userParseObjArr),
      err => reject(err)
    );
  });
  return promise;
}

function getUsersForCompany(companyParseObj) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getUsersForCompany', { company: getPointer('Company', companyParseObj.id) }).then(
      userParseObjArr => resolve(userParseObjArr)
    );
  });
  return promise;
}

function checkSupportDisableEldDailyCert(companyId) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('support:checkSupportDisableEldDailyCert', { companyId }).then(
      userParseObjArr => resolve(userParseObjArr)
    );
  });
  return promise;
}

function getSupportReports(filterArr) {
  return Parse.Cloud.run('_internal:querySupportReport', { masterKey: store.getState().Main.parseMasterKey, filterArr });
}


function getDispatchersForCompany(companyParseObj) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getDispatchersForCompany', { company: getPointer('Company', companyParseObj.id) }).then(
      dispatcherParseObjArr => resolve(dispatcherParseObjArr)
    );
  });
  return promise;
}

function getActivityForCompany(companyParseObj) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:getActivity', { company: getPointer('Company', companyParseObj.id) }).then(
      dispatcherParseObjArr => resolve(dispatcherParseObjArr)
    );
  });
  return promise;
}

function getSimByImei(imei) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:getSimByImei', { imei }).then(
      sim => resolve(sim),
      error => reject(error)
    );
  });
  return promise;
}

function getSimUsageRecordsForMonth(date, iccid) {
  return Parse.Cloud.run('_internal:getSimUsageRecordsForMonth', { masterKey: store.getState().Main.parseMasterKey, date, iccid });
}

function getSimArr(valueType, value) {
  return Parse.Cloud.run('_internal:getSimArr', { masterKey: store.getState().Main.parseMasterKey, valueType, value });
}

function getVehicles(filter, showDisabled, showHidden) {
  return Parse.Cloud.run('_internal:getVehicles', { filter, showDisabled, showHidden });
}

function getTrailers(filter, showDisabled, showHidden) {
  return Parse.Cloud.run('_internal:getTrailers', { filter, showDisabled, showHidden });
}


export {
  checkSupportDisableEldDailyCert,
  getActiveSubscriptions,
  getActiveCountsForCompany,
  getSubscriptionsWithIfta,
  getCompanyByName,
  getParentChildCompaniesForCompany,
  getModulesForCompany,
  getAndRefreshSimsForCompany,
  checkAndGetActiveVehiclesCountObjForCompany,
  getCompanyById,
  getDriverByIMEI,
  getDriverByUserId,
  getEldDailyCertificationsForDriver,
  getOutdatedUsers,
  getSessionToken,
  getSupportReports,
  getUsersByProperty,
  getUsersForCompany,
  getDispatchersForCompany,
  getResellerCompanies,
  getSimByImei,
  getSimUsageRecordsForMonth,
  getSimArr,
  getVehicles,
  getTrailers
};
