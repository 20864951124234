import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';


// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

const initialState = {
  driver: undefined,
  showEditDriverOdometersModal: false,
  showEditTripInspectionsModalModal: false,
  savingELDHardwareTargetSoftwareVersion: false,
  savingForcedELD: false,
}

class OfficeUserDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          keyboard={false}
          backdrop="static"
          bsSize="lg"
          onHide={this.props.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.user &&
                `${this.props.user.get('firstName')} ${this.props.user.get('lastName')}`
              }
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <i>userId: {this.props.user && this.props.user.id}</i>
              <br />
              <i>belongsToCompanyId: {this.props.user && this.props.user.get('belongsToCompany').id}</i>
              <br />
              <br />
              {/* { this.props.user &&
                <button className="buttonDefault" onClick={() => this.props.showChangeEmailModal(this.props.user)}>Change Email</button>
              }<br /> */}
              <br />
              {this.props.user && !this.props.user.get('allowInternalToolUsage') &&
                <button className="buttonDefault" onClick={() => this.props.showResetPasswordModal(this.props.user)}>Reset Password</button>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}

OfficeUserDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default OfficeUserDetailsModal;
