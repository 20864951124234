import PropTypes from 'prop-types';
import React from 'react';

// CSS
import styles from './Title.module.scss';

class Title extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className={styles.layoutTitle}>
        <table>
          <tbody>
            <tr>
              <td className={styles.one}>
                <h1 className={`${styles.title} ${this.props.className}`}>{this.props.title}</h1>
              </td>
              <td className={styles.options}>
                {this.props.children}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Title;
