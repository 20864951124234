import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import { Modal } from 'react-bootstrap';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

import * as ParseAPI from 'api/Parse';

import store from '../../../store';


// Components

// styles
import styles from './ResetPasswordModal.module.scss';

function getInitialState() {
  return {
    accountInfo: {
      password: '',
      passwordConfirm: '',
    },
  };
}

class ResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(getInitialState());
    }
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  handleReset() {
    this.setState({ ...this.state, error: undefined }, (newState) => {
      if (this.state.accountInfo.password !== this.state.accountInfo.passwordConfirm) {
        this.setState({ ...this.state, error: 'password does not match' });
        return;
      }
      if (this.props.user) {
        Parse.Cloud.run('_internal:resetPassword', {
          masterKey: store.getState().Main.parseMasterKey,
          userId: this.props.user.id,
          password: this.state.accountInfo.password,
        }).then((asdf) => {
          this.props.hideModal();
        }, (error) => {
          console.log(error);
        });
      }
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputs}>
            <TextField
              floatingLabelText="Password"
              onChange={(event, newValue) => this.handleChangeInfo('password', newValue)}
              value={this.state.accountInfo.password}
            />
            <br />
            <TextField
              floatingLabelText="Confirm Password"
              onChange={(event, newValue) => this.handleChangeInfo('passwordConfirm', newValue)}
              value={this.state.accountInfo.passwordConfirm}
            />
          </div>
          <div>
            { this.state.error &&
              <div className={styles.error}>
                {this.state.error}
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.hideModal} >Cancel</button>
            <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleReset}>Reset Password</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ResetPasswordModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default ResetPasswordModal;
