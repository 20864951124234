import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';

// Components

// styles
import styles from './ResetDataModal.module.scss';

class ResetBypassModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
    const vehicleObj = this.props.vehicleObj;
    this.setState({ ...this.state, loading: true }, () => {
      Getters.getCompanyById(vehicleObj.get('belongsToCompany').id).then((companyObj) => {
        console.log(companyObj);
        if (!companyObj) return false;
        const subscriptionObj = companyObj && companyObj.get('subscription');
        const addressObj = companyObj && companyObj.get('address');

        console.log('resetting!');
        console.log(companyObj && companyObj.id,
          subscriptionObj && subscriptionObj.id,
          addressObj && addressObj.id, vehicleObj && vehicleObj.id,
          false
        );
        Setters.handleWSBVehicleSubscription(
          companyObj && companyObj.id,
          subscriptionObj && subscriptionObj.id,
          addressObj && addressObj.id, vehicleObj && vehicleObj.id,
          false
        ).then(() => {
          console.log('disabled');
          Setters.handleWSBVehicleSubscription(
            companyObj && companyObj.id,
            subscriptionObj && subscriptionObj.id,
            addressObj && addressObj.id, vehicleObj && vehicleObj.id,
            true
          ).then(() => {
            console.log('enabled');
            this.setState({ ...this.state, loading: false });
          });
        }, (error) => {
          console.log(error);
          this.setState({ ...this.state, loading: false, error: error.message });
        });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Weigh Station Bypass</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            Are you sure? The driver will lose Bypass features for a short period of time.
          </div>
          <br />
          <br />
          <div>
            {this.state.error &&
              <div className={styles.error}>
                {this.state.error}
                <br />
                <br />
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose}>Cancel</button>
            <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleReset} disabled={this.state.loading}>Reset Bypass</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ResetBypassModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  vehicleObj: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default ResetBypassModal;
