import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.handleBadgeClick = this.handleBadgeClick.bind(this);
    this.handleClickOutsideBadge = this.handleClickOutsideBadge.bind(this);
    this.handleClickOutsidePanel = this.handleClickOutsidePanel.bind(this);
    this.handleNotificationHover = this.handleNotificationHover.bind(this);
  }

  handleBadgeClick() {
    toggleNotificationPanel();
  }

  handleClickOutsidePanel() {
    // toggleNotificationPanel();
  }

  handleClickOutsideBadge() {
    // toggleNotificationPanel();
  }

  handleNotificationHover(notification) {
    if (!notification.read) {
      readNotification(notification);
    }
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

Notifications.propTypes = {
  Notification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Notification } = state;
  return {
    Notification,
  };
};

export default connect(mapStateToProps)(Notifications);
