import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';

// API
import { compressWhitespace } from 'api/Helpers';

// Components
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';

// CSS
import styles from './FilterForm.module.scss';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      buttonSwap: false, // determine if we need to hide the filter button for the clear button, or vice-versa
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  onSubmit(e) {
    // prevent page from reloading when form submits
    e.preventDefault();
  }

  handleChange(e) {
    // add attr/val of user input to state
    this.state.filter[e.target.id] = e.target.value;
    this.state.buttonSwap = false;
    this.setState(this.state);
  }

  handleDateChange(dateMoment, attrName) {
    // add attr/val of user input to state
    this.state.filter[attrName] = dateMoment;
    this.state.buttonSwap = false;
    this.setState(this.state);
  }

  handleFilter() {
    // pass up input to parent
    const filterObject = {};
    const filterKeys = Object.keys(this.state.filter); // get the keys used, which is stored as this components state
    const filterKeysLen = filterKeys.length;
    for (let i = 0; i < filterKeysLen; i++) {
      // if the filter key is used, add it to our filter object
      const currentKey = filterKeys[i];
      const currentValue = this.state.filter[currentKey];
      if (typeof currentValue === 'string') {
        if (currentValue.length > 0 && compressWhitespace(currentValue) !== '') {
          filterObject[currentKey] = currentValue;
        }
      }
      if (typeof currentValue === 'object') {
        if (currentValue && Object.keys(currentValue).length > 0) {
          filterObject[currentKey] = currentValue;
        }
      }
    }
    if (Object.keys(filterObject).length > 0) {
      // if the filter is dirty, swap the button fo sho
      this.state.buttonSwap = true;
    }
    this.props.handleFilter(filterObject);
    this.setState(this.state);
  }

  clearFilter() {
    // parent defined clear
    this.props.clearFilter();
    this.state.buttonSwap = false;
    this.setState(this.state);
  }

  render() {
    // Take fields given from parent of format {fieldName: Field Display Name}
    this.formGroups = this.props.fields.map(field => {
      if (field.type === 'date') {
        return (
          <span key={field.attrName}>
            <FormGroup controlId={field.attrName}>
              <label className={styles.filterLabel}>{field.fullName}</label>
              <DatePicker
                className={styles.filterInput}
                type={field.type}
                placeholder={field.placeholder}
                value={this.state.filter[field.attrName]}
                ref={`input${field.attrName}`}
                onChange={(date) => this.handleDateChange(date, field.attrName)}
                autoComplete="off"
              />
            </FormGroup>
            {' '}
          </span>
        );
      }
      return (
        <span key={field.attrName}>
          <FormGroup controlId={field.attrName}>
            <label className={styles.filterLabel}>{field.fullName}</label>
            <FormControl
              className={styles.filterInput}
              type={field.type}
              placeholder={field.placeholder}
              value={this.state.filter[field.attrName] || ''}
              ref={`input${field.attrName}`}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </FormGroup>
          {' '}
        </span>
      );
    });

    if (!this.state.buttonSwap) {
      this.filterOrClearButton = (
        <button type="submit" className={`buttonDefaultRound ${styles.buttonFilter}`} onClick={this.handleFilter}>
          <Glyphicon className={styles.glyphFilter} glyph="filter" />
        </button>
      );
    } else {
      this.filterOrClearButton = (
        <button className={`buttonDefaultRound ${styles.buttonClear}`} onClick={this.clearFilter}>
          <Glyphicon className={styles.glyphClear} glyph="remove" />
        </button>
      );
    }

    return (
      <div className={this.props.className}>
        <Form inline className={styles.filterForm} onSubmit={this.onSubmit}>
          {this.formGroups}
          {this.filterOrClearButton}
        </Form>
        <br />
      </div>
    );
  }
}

FilterForm.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
};


export default FilterForm;
