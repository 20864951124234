import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
// CSS
import styles from './ErrorModal.css';

function ErrorModal(props) {
  return (
    <Modal
      backdrop="static"
      show={props.show}
      onHide={props.handleClose}
    >

      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.modalBody}>
          {props.children}
        </div>
      </Modal.Body>

    </Modal>
  );
}

ErrorModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ErrorModal;
