import React from 'react';
import ReactDatePicker from 'react-datepicker';

require('react-datepicker/dist/react-datepicker.css');

function DatePicker(props) {
  return (
    <ReactDatePicker
      {...props}
    />
  );
}

export default DatePicker;
