import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  status: '',
  users: [],
  fetched: true,
};

export function User(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_USERS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
      };
    case ActionConstants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        status: action.type,
        users: action.users,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_USERS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: true,
      };
    case ActionConstants.DELETE_USERS: {
      return initialState;
    }
    default:
      return state;
  }
}
