import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

// Components

// API
import * as Setters from 'api/Setters';

// styles
import styles from './_modal.module.scss';
import { ActionSettingsBackupRestore } from 'material-ui/svg-icons';

function getInitialState() {
  return {
    accountInfo: {
      imei: '',
      imeiConfirm: '',
      iccid: '',
      iccidConfirm: '',
    },
    subscriptionNoteObj: {
      name: '',
      reason: '',
    },
    loading: false,
  };
}

class ToggleSimModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleActivateDeactivate = this.handleActivateDeactivate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(getInitialState());
    }
  }

  handleChangeInfo(property, value, subscriptionNoteObjBool) {
    const newState = { ...this.state };
    if (subscriptionNoteObjBool) {
      newState.subscriptionNoteObj[property] = value;
    } else {
      newState.accountInfo[property] = value;
    }
    this.setState(newState);
  }

  handleActivateDeactivate(activateBool) {
    this.setState({ ...this.state, error: undefined, loading: true }, (newState) => {
      if (this.state.accountInfo.imei !== this.state.accountInfo.imeiConfirm) {
        this.setState({ ...this.state, error: 'imei does not match', loading: true });
        return;
      }

      if (
        this.props.user &&
        this.props.user.get('belongsToCompany')
      ) {
        Setters.toggleSimForCompany(
          this.props.user.get('belongsToCompany').id,
          this.state.accountInfo.imei,
          this.state.accountInfo.iccid,
          activateBool,
          moment().format('ll') + ' - IMEI: ' + this.state.accountInfo.imei + ' - Active: ' + activateBool + ' - (Support Tool): ' + this.state.subscriptionNoteObj.name + ': ' + this.state.subscriptionNoteObj.reason,
        ).then(() => {
          this.setState({ ...this.state, loading: false });
          // const updatedNote = moment().format('ll') + ' - IMEI: ' + this.state.accountInfo.imei + ' - Active: ' + activateBool + ' - ' + this.state.subscriptionNoteObj.name + ': ' + this.state.subscriptionNoteObj.reason;
          // Setters.addSubscriptionObjNote(this.state.accountInfo.subscriptionObj, updatedNote);
          Setters.sendEmailOffline(
            'billing@onswitchboard.com',
            `${activateBool ? 'Activation' : 'Deactivation'} of SIM by Support Tool - ${this.props.user?.get('belongsToCompany')?.get('name')}`,
            `This is to inform that the Support Tool has ${activateBool ? 'activated' : 'deactivated'} SIM for ${this.props.user?.get('belongsToCompany')?.get('name')}: \nICCID: ${this.state.accountInfo.iccid}\nIMEI: ${this.state.accountInfo.imei}\n\nPlease update the billing accordingly.\n\n${this.state.subscriptionNoteObj.name}: ${this.state.subscriptionNoteObj.reason}`
          );
          this.handleClose();
        }, (error) => {
          this.setState({ ...this.state, error: error && error.message, loading: false });
          console.log(error);
        });
      }
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activate / Deactivate</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputs}>
            <TextField
              floatingLabelText="Tablet IMEI"
              onChange={(event, newValue) => this.handleChangeInfo('imei', newValue)}
              value={this.state.accountInfo.imei}
            />
            <TextField
              floatingLabelText="Confirm Tablet IMEI"
              onChange={(event, newValue) => this.handleChangeInfo('imeiConfirm', newValue)}
              value={this.state.accountInfo.imeiConfirm}
            />
            <br />
            <br />
            <br />
            <b>If you don't have the ICCID, try just entering the IMEI</b>
            <br />
            <TextField
              floatingLabelText="Sim ICCID"
              onChange={(event, newValue) => this.handleChangeInfo('iccid', newValue)}
              value={this.state.accountInfo.iccid}
            />
            <TextField
              floatingLabelText="Confirm Sim ICCID"
              onChange={(event, newValue) => this.handleChangeInfo('iccidConfirm', newValue)}
              value={this.state.accountInfo.iccidConfirm}
            />
            <br />
            <TextField
              floatingLabelText="Support Personnel Name"
              onChange={(event, newValue) => this.handleChangeInfo('name', newValue, true)}
              value={this.state.subscriptionNoteObj.name}
            />
            <TextField
              floatingLabelText="Reason"
              onChange={(event, newValue) => this.handleChangeInfo('reason', newValue, true)}
              value={this.state.subscriptionNoteObj.reason}
            />
          </div>
          <div>
            {this.state.error &&
              <div className={styles.error}>
                {this.state.error}
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose} >Close</button>
            <button
              className={`${styles.resetButton} buttonDefault`}
              onClick={() => this.handleActivateDeactivate(true)}
              disabled={
                this.state.loading ||
                !this.state.subscriptionNoteObj.reason ||
                !this.state.subscriptionNoteObj.name ||
                (this.state.accountInfo.imei !== this.state.accountInfo.imeiConfirm) ||
                (this.state.accountInfo.iccid !== this.state.accountInfo.iccidConfirm)
              }
            >
              Activate
            </button>
            &nbsp;
            <button
              className={`${styles.resetButton} buttonDefault`}
              onClick={() => this.handleActivateDeactivate(false)}
              disabled={
                this.state.loading ||
                !this.state.subscriptionNoteObj.reason ||
                !this.state.subscriptionNoteObj.name ||
                (this.state.accountInfo.imei !== this.state.accountInfo.imeiConfirm) ||
                (this.state.accountInfo.iccid !== this.state.accountInfo.iccidConfirm)
              }
            >
              Deactivate
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ToggleSimModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default ToggleSimModal;
