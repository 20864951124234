import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
// CSS
import styles from './ConfirmModal.module.scss';

function ConfirmModal(props) {
  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.modalBody}>Are you sure?</div>
        <div className={styles.choices}>
          <button className="closeButton" onClick={() => props.handleModalChoice(false)}>No</button>
          <button className="buttonDefault" onClick={() => props.handleModalChoice(true)}>Yes</button>
        </div>
      </Modal.Body>

    </Modal>
  );
}

ConfirmModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ConfirmModal;
