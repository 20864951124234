import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  documentCategories: [],
};

export function DocumentCategory(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_DOCUMENTCATEGORIES_INPROGRESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.FETCH_DOCUMENTCATEGORIES_SUCCESS:
      return {
        ...state,
        status: action.type,
        documentCategories: action.documentCategories,
        error: undefined,
      };
    case ActionConstants.FETCH_DOCUMENTCATEGORIES_ERROR:
      return {
        ...state,
        status: action.type,
        documentCategories: {},
        error: action.error,
      };
    default:
      return state;
  }
}
