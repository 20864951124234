import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Actions

// Components

// CSS
import styles from './SupportReportTableRow.module.scss';

class SupportReportTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      supportReport: props.supportReport.toJSON(),
      phoneNumber: (props.supportReport.get('user') && props.supportReport.get('user').get('phoneNumberCleaned')) || (props.supportReport.get('company') && props.supportReport.get('company').get('phoneNumber')),
      sendingToContactAutomation: false,
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveSupportReport = this.saveSupportReport.bind(this);
  }

  handleChange(property, value) {
    const newSupportReport = { ...this.state.supportReport };
    newSupportReport[property] = value;
    if (property === 'solved') {
      if (value === true) {
        this.props.supportReport.set('resolveDate', moment().toDate());
        newSupportReport.resolveDate = moment().toDate();
      } else {
        this.props.supportReport.unset('resolveDate');
        newSupportReport.resolveDate = undefined;
      }
    }
    this.setState({ ...this.state, supportReport: newSupportReport });
    this.props.supportReport.set(property, value);
  }

  saveSupportReport() {
    this.setState({ ...this.state, saving: true });
    this.props.supportReport.save().then(() => {
      this.setState({ ...this.state, saving: false, supportReport: this.props.supportReport.toJSON()})
    });
  }

  render() {
    const supportReport = this.state.supportReport;
    return (
      <tr className={supportReport.solved ? styles.solved : styles.unsolved}>
        <td>
          Create: <b>{ moment(supportReport.dateTime.iso).format('MMM D, YYYY') }</b>
          <br />
          Update: <b>{ moment(supportReport.updatedAt.iso).format('MMM D, YYYY') }</b>
          <br />
          Resolve: <b>{ supportReport.resolveDate && moment(supportReport.resolveDate.iso).format('MMM D, YYYY') }</b>
          <br />
          <select value={supportReport.solved || false} onChange={(e) => this.handleChange('solved', e.target.value === 'true' ? true : false)}>
            <option value={true}>Solved</option>
            <option value={false}>Unsolved</option>
          </select>
        </td>
        <td>
          { supportReport.user && 
            <div>
              <b>{ supportReport.user.firstName} {supportReport.user.lastName }</b> 
              <br />
              <b>{ supportReport.user.username }</b>
            </div>
          }
          <div>
            { supportReport.company && supportReport.company.name }
          </div>
        </td>
        <td>
          <textarea value={supportReport.problem} onChange={(e) => this.handleChange('problem', e.target.value) } />
        </td>
        <td>
          <textarea value={supportReport.solution} onChange={(e) => this.handleChange('solution', e.target.value) } />
        </td>
        <td>
          <button 
            className="buttonDefault yellowButton" 
            onClick={() => this.saveSupportReport(this.props.user)} 
            disabled={this.state.saving}
          >
            Save
          </button>
          <br /><br />
          Phone #: <input style={{ width: '50%' }} onChange={(e) => this.setState({ ...this.state, phoneNumber: e.target.value })} value={this.state.phoneNumber} />
          <br />
          <button 
            className="buttonDefault yellowButton" 
            onClick={() => {
              this.setState({ ...this.state, sendingToContactAutomation: true }, () => {
                this.saveSupportReport(this.props.user).then(() => {
                  // Setters.sendToContactAutomation(this.props.supportReport.id, this.state.phoneNumber).then(() => {
                  //   this.setState({ ...this.state, sendingToContactAutomation: false });
                  // }).catch((err) => {
                  //   this.setState({ ...this.state, sendingToContactAutomation: false, error: err.message });
                  // });
                });
              });
            }} 
            disabled={this.state.sendingToContactAutomation}
          >
            Send to CA
          </button>
          <br />
          { this.state.error &&
            <div className={styles.error}> {this.state.error }</div>
          }
        </td>
      </tr>
    );
  }
}

SupportReportTableRow.propTypes = {
  supportReport: PropTypes.object.isRequired,
};

export default SupportReportTableRow;
