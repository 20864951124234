import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'sbHistory';

// Actions
import { tryLogin } from 'actions/Auth';

// Helpers
import { getCurrentUser } from 'api/Parse';

// Components
import LoginForm from '../view/LoginForm';


function getUsername() {
  const user = getCurrentUser();
  if (user) {
    return user.get('username');
  }
  return null;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordModal: false,
      fields: {
        email: getUsername() ? getUsername() : '',
        password: '',
      },
      disableLogin: false,
    };
    this.tryLogin = this.tryLogin.bind(this);
    this.emailHandler = this.emailHandler.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  tryLogin() {
    this.setState({ ...this.state, disableLogin: true });
    tryLogin(this.state.fields).then(() => {
      this.setState({ ...this.state, disableLogin: false });
      history.push('/home');
    }, () => {
      this.setState({ ...this.state, disableLogin: false });
    });
  }

  handleFormChange(property, value) {
    const newState = { ...this.state };
    newState.fields[property] = value;
    this.setState(newState);
  }

  emailHandler(email) {
    // opens modal to confirm that the user does want to go through with it
    this.setState({ ...this.state, showForgotPasswordModal: true, email });
  }

  sendEmail(email) {
    const promise = new Promise((resolve, reject) => {
    });
    return promise;
  }

  handleClose() {
    this.setState({ ...this.state, showForgotPasswordModal: false });
  }

  render() {
    return (
      <div>
        <LoginForm authErrorObject={this.props.Auth ? this.props.Auth.error : undefined} disableLogin={this.state.disableLogin} emailHandler={this.emailHandler} handleSubmit={this.tryLogin} handleFormChange={this.handleFormChange} fields={this.state.fields} />
      </div>
    );
  }
}

Login.propTypes = {
  Auth: PropTypes.object,
};

const mapStateToProps = (state) => {
  if (state) {
    const { Auth } = state;
    return {
      Auth,
    };
  }
  return 0;
};

export default connect(mapStateToProps)(Login);
