import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'sbHistory';

// Layouts
import LoginLayout from 'layouts/LoginLayout/LoginLayout';
import CompaniesLayout from 'layouts/CompaniesLayout/CompaniesLayout';
import UsersLayout from 'layouts/UsersLayout/UsersLayout';
import HomeLayout from 'layouts/HomeLayout/HomeLayout';
import HubspotLayout from 'layouts/HubspotLayout/HubspotLayout';
import SimsLayout from 'layouts/SimsLayout/SimsLayout';
import TicketLayout from 'layouts/TicketLayout/TicketLayout';
import ScriptsLayout from 'layouts/ScriptsLayout/ScriptsLayout';
import InAppLayout from 'layouts/InAppLayout/InAppLayout';
import SupportReportLayout from 'layouts/SupportReportLayout/SupportReportLayout';
import EquipmentLayout from 'layouts/EquipmentLayout/EquipmentLayout';

export const baseURL = '/';

export default (
  <Router history={history}>
    <Switch>
      <Route path="/login" component={LoginLayout} />

      <InAppLayout>
        <Route path="/home" component={HomeLayout} />
        <Route path="/ticket" component={TicketLayout} />
        <Route path="/companies" component={CompaniesLayout} />
        <Route path="/users" component={UsersLayout} />
        <Route path="/sims" component={SimsLayout} />
        <Route path="/hubspot" component={HubspotLayout} />
        <Route path="/scripts" component={ScriptsLayout} />
        <Route path="/supportReport" component={SupportReportLayout} />
        <Route path="/equipment" component={EquipmentLayout} />
      </InAppLayout>
    </Switch>
  </Router>
);
