/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import axios from 'axios';
import store from '../store';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

import { initialize } from 'actions/Main';

function convertDriversEnabled() {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:convertDriversEnabled', { masterKey: store.getState().Main.parseMasterKey }).then((asdf) => {
      console.log(asdf);
    });
  });
  return promise;
}

function cloneDailyCertificationEventsLocation(sourceELDDailyCertificationId, destinationCompanyId, destinationCompanyName, destinationDriverId, destinationVehicleId, destinationVehicleUnitId, username, password) {
  if (!sourceELDDailyCertificationId || !destinationDriverId || !destinationCompanyId || !destinationCompanyName || !destinationVehicleId || !destinationVehicleUnitId) {
    console.error('missing info');
    return;
  }
  // Parse.Cloud.run('_migration:companiesAndSubscription', { masterKey: store.getState().Main.parseMasterKey }).then(() => console.log('ok'), error => console.log(error));
  // Parse.Cloud.run('_migration:usersToCompanyChannels', { masterKey: store.getState().Main.parseMasterKey }).then(() => console.log('ok'), error => console.log(error));
  Parse.Cloud.run('_internal:getProductionEventsForDailyCert', { masterKey: store.getState().Main.parseMasterKey, eldDailyCertificationId: sourceELDDailyCertificationId }).then((asdf) => {
    if (!asdf.eldDailyCertification) {
      console.error('No ELDDailyCertification');
      return;
    }
    initialize(true).then(() => {
      Parse.User.logIn(username, password).then(() => {
        const company = new Parse.Object('Company');
        company.id = destinationCompanyId;

        const companyName = destinationCompanyName;
        const driver = new Parse.Object('Driver');
        driver.id = destinationDriverId;

        const vehicle = new Parse.Object('Vehicle');
        vehicle.id = destinationVehicleId;

        const vehicleUnitId = destinationVehicleUnitId;

        const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
        eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', asdf.eldDailyCertification.get('startTimeUTC'));
        eldDailyCertificationQuery.lessThanOrEqualTo('endTimeUTC', asdf.eldDailyCertification.get('endTimeUTC'));
        eldDailyCertificationQuery.equalTo('driver', driver);
        eldDailyCertificationQuery.first().then((eldDailyCertificationParseObj) => {
          const eldEventQuery = new Parse.Query('ELDEvent');
          eldEventQuery.equalTo('eldDailyCertification', eldDailyCertificationParseObj);
          eldEventQuery.find().then((eldEventParseArr) => {
            if (eldDailyCertificationParseObj) {
              Parse.Object.destroyAll([].concat(...[eldDailyCertificationParseObj, eldEventParseArr]));
            }
          });
        });

        const clonedELDDailyCertification = asdf.eldDailyCertification.clone();
        clonedELDDailyCertification.set('driver', driver.toPointer());
        clonedELDDailyCertification.save().then((newClonedELDDailyCertification) => {
          const companyACL = new Parse.ACL();
          companyACL.setRoleWriteAccess(company.id, true);
          companyACL.setRoleReadAccess(company.id, true);
          console.log(asdf.eldEvents.length);
          console.log(asdf.vehicleLocations.length);
          for (let i = 0; i < asdf.eldEvents.length; i++) {
            const eldEvent = asdf.eldEvents[i];
            const clonedELDEvent = eldEvent.clone();
            clonedELDEvent.set('company', company.toPointer());
            clonedELDEvent.set('companyName', companyName);
            clonedELDEvent.set('driver', driver);
            clonedELDEvent.set('vehicle', vehicle);
            clonedELDEvent.set('vehicleUnitId', vehicleUnitId);
            clonedELDEvent.set('eldDailyCertification', newClonedELDDailyCertification);
            clonedELDEvent.setACL(companyACL);
            clonedELDEvent.save().then((newClonedELDEvent) => {
              console.log(`saved ELDEvent: ${i}`);
              let clonedVehicleLocation;
              for (let j = 0; j < asdf.vehicleLocations.length; j++) {
                const vehicleLocation = asdf.vehicleLocations[j];
                if (eldEvent.get('vehicleLocation') && vehicleLocation.id === eldEvent.get('vehicleLocation').id) {
                  clonedVehicleLocation = eldEvent.get('vehicleLocation').clone();
                }
              }
              if (clonedVehicleLocation) {
                clonedVehicleLocation.set('vehicle', vehicle.toPointer());
                clonedVehicleLocation.set('drivers', [driver.toPointer()]);
                clonedVehicleLocation.set('eldEvent', newClonedELDEvent);
                clonedVehicleLocation.setACL(companyACL);
                clonedVehicleLocation.save().then(() => console.log('saved VehicleLocation'));
              }
            }, (error) => {
              console.log(error);
            });
          }
        }, (error) => {
          console.log(error);
        });
      }, (loginerror) => {
        console.log(loginerror);
      });
    });
  }, error => {
    console.log(error);
  });
}

function setAllEventsAndLocationsToACL(eldDailyCertificationIdArr, companyId) {
  const promise = new Promise((resolve) => {
    const promises = [];
    for (let i = 0; i < eldDailyCertificationIdArr.length; i++) {
      promises.push(Parse.Cloud.run('_internal:setAllEventsAndLocationsToACL', { masterKey: store.getState().Main.parseMasterKey, eldDailyCertificationId: eldDailyCertificationIdArr[i], companyId }));
      Promise.all(promises).then(() => {
        resolve();
      }, (error) => {
        console.log(error);
        resolve();
      });
    }
  });
  return promise;
}

function resetSim(simIccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:resetSim', { simIccid }).then(
      sim => resolve(sim),
      error => reject(error)
    );
  });
  return promise;
}

function sendELDIssuePdf(driverId, email, driverEmail) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:sendELDIssuePdf', { driverId, email, driverEmail }).then(
      driver => resolve(driver),
      error => reject(error)
    );
  });
  return promise;
}

function createSupportReport(userObj) {
  const company = userObj.get('belongsToCompany');
  const supportReport = new Parse.Object('SupportReport');
  supportReport.set('user', userObj);
  supportReport.set('company', company);
  supportReport.set('dateTime', new Date());
  return supportReport.save();
}

function setDisableEldDailyCertification(eldDailyCertificationId, disableBool) {
  return Parse.Cloud.run('_internal:setDisableEldDailyCertification', {
    eldDailyCertificationId,
    disableBool,
  });
}

function sendELDIssuePdfOffline(email, companyName, vehicleUnitId, driverName) {
  const promise = new Promise((resolve, reject) => {
    axios.post('/eldIssue', {
      email, companyName, vehicleUnitId, driverName,
    }, {
      responseType: 'blob',
    }).then(
      response => resolve(response),
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

function sendEmailOffline(emailAddress, subjectLine, body) {
  const promise = new Promise((resolve, reject) => {
    axios.post('/sendEmailOffline', {
      emailAddress, subjectLine, body
    }).then(
      response => resolve(response),
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

function sendUnableToReachEmail(email, name) {
  const promise = new Promise((resolve, reject) => {
    axios.post('/sendUnableToReachEmail', {
      email,
      name,
    }).then(
      response => resolve(response),
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

function restartUserSoftware(userId) {
  return Parse.Cloud.run('restartUserSoftware', { userId, force: true });
};


function setELDEventsToAOBRD(driverId, date) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('support:setDayELDEventsToAOBRDEnabled', { driverId, date }).then(
      response => {
        console.log(response);
        resolve(response);
      },
      error => reject(error)
    );
  });
  return promise;
}

function setAutogeneratedForDriver(driverId) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('internal:setAutogeneratedForDriver', {
      masterKey: store.getState().Main.parseMasterKey,
      driverId
    }).then(
      response => {
        console.log(response);
        resolve(response);
      },
      error => {
        console.log(error);
        reject(error);
      },
    );
  });
  return promise;
}

function toggleSimForCompany(companyId, imei, iccid, isActive, reason, ratePlanSid) {
  return Parse.Cloud.run('_internal:toggleSimState', {
    masterKey: store.getState().Main.parseMasterKey,
    value: iccid ? iccid : imei,
    valueType: iccid ? 'iccid' : 'imei',
    isActive,
    companyId,
    ratePlanSid
  }).then(() => {
    Parse.Cloud.run('_internal:setSimForCompany', {
      masterKey: store.getState().Main.parseMasterKey,
      imei,
      iccid,
      companyId,
      isActive,
      reason,
    })
  }).catch((err) => {
    Parse.Cloud.run('_internal:setSimForCompany', {
      masterKey: store.getState().Main.parseMasterKey,
      imei,
      iccid,
      companyId,
      reason,
    });
    return Promise.reject(err);
  });
}

function addSubscriptionObjNote(subscriptionObj, note) {
  return Parse.Cloud.run('_support:addSubscriptionObjNote', {
    masterKey: store.getState().Main.parseMasterKey,
    subscriptionId: subscriptionObj.id,
    note,
  });
}

const handleWSBVehicleSubscription = (companyId, subscriptionId, addressId, vehicleId, enabled) => {
  return Parse.Cloud.run('handleWSBVehicleSubscription',
    {
      companyId,
      subscriptionId,
      addressId,
      vehicleId,
      enabled,
    }
  );
};

const publishUpdateForScalefusionDevice = (imei) => {
  return Parse.Cloud.run('publishUpdateForScalefusionDevice',
    {
      imei
    }
  );
}

export {
  convertDriversEnabled,
  cloneDailyCertificationEventsLocation,
  setAllEventsAndLocationsToACL,
  resetSim,
  setDisableEldDailyCertification,
  sendELDIssuePdf,
  sendELDIssuePdfOffline,
  sendEmailOffline,
  sendUnableToReachEmail,
  setELDEventsToAOBRD,
  createSupportReport,
  setAutogeneratedForDriver,
  restartUserSoftware,
  toggleSimForCompany,
  addSubscriptionObjNote,
  handleWSBVehicleSubscription,
  publishUpdateForScalefusionDevice,
};
