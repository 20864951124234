import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Components
import ConfirmModalView from 'components/ConfirmModal/view/ConfirmModal';

class ConfirmModal extends React.Component {
  render() {
    const { handleModalChoice, handleClose, show } = this.props;
    return (
      <ConfirmModalView show={show} handleModalChoice={handleModalChoice} handleClose={handleClose} />
    );
  }
}

ConfirmModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default connect(undefined, undefined)(ConfirmModal);
