import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Actions

// Components
import ErrorModal from 'components/ErrorModal/ErrorModal';

// CSS
import styles from './EquipmentRow.css';

class TrailerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commandString: '',
      error: '',
      loading: false,
    };

    this.sendCommand = this.sendCommand.bind(this);
  }

  componentDidMount() {

  }

  sendCommand(uniqueid) {
    this.setState({ ...this.state, loading: true }, () => {
      // AT+GTRTO=gv600mg,9,,3,,,,FFFF$ (for Queclink status update)
      // AT+GTRTO=gv600mg,30,,3,,,,FFFF$ (for Queclink temp sensor)
      Setters.sendDeviceCommand(uniqueid, this.state.commandString).then(() => {
        this.setState({ ...this.state, loading: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false, error: error.message });
      });
    });
  }

  render() {
    const trailerObj = this.props.trailerObj;
    return (
      <tr className={styles.userRow}>
        <td>
          {trailerObj.get('unitId')}
        </td>
        <td>
          {trailerObj.get('belongsToCompany') && trailerObj.get('belongsToCompany').get('name')}
        </td>
        <td>
          {trailerObj.get('tc_devices') &&
            <div>
              {trailerObj.get('tc_devices').get('uniqueid')}
            </div>
          }
        </td>
        <td>
          {moment(trailerObj.get('updatedAt')).format('LLL')}
        </td>
        <td>
          {trailerObj.get('tc_devices') &&
            <div>
              <input placeholder="Send Command to Asset Tracker" onChange={(e) => this.setState({ ...this.state, commandString: e.target.value })} value={this.state.commandString} />
              <button className="buttonDefault" disabled={this.state.loading} onClick={() => this.sendCommand(trailerObj.get('tc_devices').get('uniqueid'))}>Send</button>
            </div>
          }
        </td>
        {this.state.error &&
          <ErrorModal show={!!this.state.error} handleModalChoice={() => this.setState({ ...this.state, error: '' })} handleClose={() => this.setState({ ...this.state, error: '' })}>
            <div>{this.state.error}</div>
          </ErrorModal>
        }
      </tr>
    );
  }
}

TrailerRow.propTypes = {
  trailerObj: PropTypes.object.isRequired,
  hideUnhideEquipment: PropTypes.func.isRequired,
};

export default TrailerRow;
