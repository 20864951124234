import Parse from 'parse';
import moment from 'moment';
import * as Helpers from './Helpers';

/**
 * @memberof module:ELD
 * @param {*} milliTime
 * @returns
 */
function formatMilliTime(milliTime) {
  if (milliTime !== undefined) {
    const hours = Helpers.convertMillisecondsToHours(milliTime);
    const hourTime = Math.floor(hours);
    const minuteTime = (`0${Math.round((hours - Math.floor(hours)) * 60)}`).slice(-2);
    return `${hourTime}:${minuteTime}`;
  }
  return undefined;
}

export {
  formatMilliTime,
};
