import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  companies: {},
  fetched: true,
};

export function Company(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_COMPANIES_INPROGRESS:
      return {
        ...state,
        companies: {},
        status: ActionConstants.FETCH_COMPANIES_INPROGRESS,
        error: undefined,
        fetched: false,
      };
    case ActionConstants.FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        status: ActionConstants.FETCH_COMPANIES_SUCCESS,
        companies: action.company,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_COMPANIES_ERROR:
      return {
        ...state,
        status: ActionConstants.FETCH_COMPANIES_ERROR,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.DELETE_DOCUMENTS:
      return initialState;
    default:
      return state;
  }
}
