import * as ActionConstants from './ActionConstants';
import * as Getters from 'api/Getters';
import * as Destroyers from 'api/Destroyers';
import store from 'store';
import axios from 'axios';

export function fetchingCompanies() {
  return {
    type: ActionConstants.FETCH_COMPANIES_INPROGRESS,
  };
}

export function fetchCompaniesSuccess(company) {
  return {
    type: ActionConstants.FETCH_COMPANIES_SUCCESS,
    company,
  };
}

export function fetchCompaniesError(error) {
  return {
    type: ActionConstants.FETCH_COMPANIES_ERROR,
    error,
  };
}

export function fetchCompaniesForState(companyName) {
  store.dispatch(fetchingCompanies());
  Getters.getCompanyByName(companyName).then(
    (companyParseObjectArr) => store.dispatch(fetchCompaniesSuccess(companyParseObjectArr)),
    (err) => store.dispatch(fetchCompaniesError(err))
  );
}

function deleteCompanies() {
  return {
    type: ActionConstants.DELETE_COMPANIES,
  };
}

export function deleteCompaniesForState() {
  store.dispatch(deleteCompanies());
}

export function destroyCompanySuccess(companyArr) {
  return {
    type: ActionConstants.DESTROY_COMPANIES_SUCCESS,
    companyArr,
  };
}

export function destroyCompany(company) {
  const promise = new Promise((resolve, reject) => {
    Destroyers.destroyCompany(company).then(() => {
      resolve();
    });
  });
  return promise;
}
