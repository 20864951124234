import PropTypes from 'prop-types';
import React from 'react';

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';

// Actions
import { destroyCompany } from 'actions/Company';

// Components
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import LoadingModal from 'components/LoadingModal/container/LoadingModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './CompanyTableRow.module.scss';

class CompanyTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = { loadingModalShow: false, destroyFinished: false };
    this.refreshActiveSimCount = this.refreshActiveSimCount.bind(this);
    this.refreshActiveVehicleCount = this.refreshActiveVehicleCount.bind(this);
    this.nameSort = this.nameSort.bind(this);
    this.state = {
      activeCountObj: {
        activeEldHardwareCount: 0,
        activeSimCount: 0,
        activeTabletSimCount: 0,
        activeVehicleLastMonthCount: 0,
        activeVehicleLastThreeMonthsCount: 0,
      },
      childCompanyArr: [],
      parentCompanyArr: [],
      moduleObj: {
        eldModule: false,
        iftaModule: false,
        mapModule: false,
        documentModule: false,
        driverBehaviourModule: false,
        dispatchModule: false,
        visionModule: false,
      },
    }
  }

  componentDidMount() {
    Getters.getActiveCountsForCompany(this.props.company.id).then((activeCountObj) => {
      if (activeCountObj) {
        const newState = {
          ...this.state,
          activeCountObj: {
            activeEldHardwareCount: activeCountObj.activeEldHardwareCount,
            activeSimCount: activeCountObj.activeSimCount,
            activeTabletSimCount: activeCountObj.activeTabletSimCount,
            activeVehicleLastMonthCount: activeCountObj.activeVehicleLastMonthCount,
            activeVehicleLastThreeMonthsCount: activeCountObj.activeVehicleLastThreeMonthsCount,
          },
        };
        this.setState(newState);
      }
    });
    Getters.getParentChildCompaniesForCompany(this.props.company.id).then((companyLinkObj) => {
      if (companyLinkObj) {
        const newState = {
          ...this.state,
          childCompanyArr: companyLinkObj.childCompanyArr,
          parentCompanyArr: companyLinkObj.parentCompanyArr,
        };
        this.setState(newState);
      }
    });
    Getters.getModulesForCompany(this.props.company.id).then((moduleObj) => {
      if (moduleObj) {
        const newState = {
          ...this.state,
          moduleObj: {
            eldModule: moduleObj.eldModule,
            iftaModule: moduleObj.iftaModule,
            mapModule: moduleObj.mapModule,
            documentModule: moduleObj.documentModule,
            driverBehaviourModule: moduleObj.driverBehaviourModule,
            dispatchModule: moduleObj.dispatchModule,
            visionModule: moduleObj.visionModule,
          },
        };
        this.setState(newState);
      }
    });
  }

  refreshActiveSimCount() {
    this.setState({ ...this.state, simCountLoading: true });
    Getters.getAndRefreshSimsForCompany(this.props.company.id).then((simArr) => {
      this.setState({
        ...this.state,
        activeCountObj: {
          ...this.state.activeCountObj,
          activeSimCount: simArr.filter((simObj) => simObj.get('isActive')).length,
        },
        simCountLoading: false,
      });
    });
  }

  refreshActiveVehicleCount() {
    this.setState({ ...this.state, activeVehicleCountLoading: true });
    Getters.checkAndGetActiveVehiclesCountObjForCompany(this.props.company.id).then((countObj) => {
      this.setState({
        ...this.state,
        activeCountObj: {
          ...this.state.activeCountObj,
          activeVehicleLastMonthCount: countObj.activeVehicleLastMonthCount.length,
          activeVehicleLastThreeMonthsCount: countObj.activeVehicleLastThreeMonthsCount.length,
        },
        activeVehicleCountLoading: false,
      });
    });
  }

  nameSort(company, nextCompany) {
    const comparatorA = company.name.toLowerCase();
    const comparatorB = nextCompany.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  render() {
    return (
      <tr className={styles.companyRow}>
        <td>{this.props.company.get('name')}</td>
        <td>
          {!this.state.activeVehicleCountLoading &&
            <span>
              Active Vehicles (Last Month): <b>{this.state.activeCountObj.activeVehicleLastMonthCount}</b>
              <br />
              Active Vehicles (Last 3 Months): <b>{this.state.activeCountObj.activeVehicleLastThreeMonthsCount}</b>
              <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.refreshActiveVehicleCount}>
                <i className={'material-icons'}>refresh</i>
              </button>
            </span>
          }
          {this.state.activeVehicleCountLoading &&
            <LoadingIcon />
          }

          <br />

          {!this.state.simCountLoading &&
            <div>
              Sim (Tablet & Tracker): <b>{this.state.activeCountObj.activeSimCount}</b>
              <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.refreshActiveSimCount}>
                <i className={'material-icons'}>refresh</i>
              </button>
            </div>
          }
        </td>
        <td>
          <b>Parent Companies:</b>
          <br/>
          {this.state.parentCompanyArr.map((parentCompanyObj) => (
            <div>
              { parentCompanyObj.get('name') }
            </div>
          ))}
          {this.state.parentCompanyArr.length === 0 &&
            <div>N/A</div>
          }
          <b>Child Companies:</b>
          <br/>
          {this.state.childCompanyArr.map((childCompanyObj) => (
            <div>
              { childCompanyObj.get('name') }
            </div>
          ))}
          {this.state.childCompanyArr.length === 0 &&
            <div>N/A</div>
          }
        </td>
        <td>
          <div>
            <b>ELD Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.eldModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.eldModule}` }
            </span>
          </div>
          <div>
            <b>IFTA Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.iftaModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.iftaModule}` }
            </span>
          </div>
          <div>
            <b>Map Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.mapModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.mapModule}` }
            </span>
          </div>
          <div>
            <b>Documents Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.documentModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.documentModule}` }
            </span>
          </div>
          <div>
            <b>Driver Behaviour Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.driverBehaviourModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.driverBehaviourModule}` }
            </span>
          </div>
          <div>
            <b>Dispatch Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.dispatchModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.dispatchModule}` }
            </span>
          </div>
          <div>
            <b>Vision Module:</b> 
            &nbsp;
            <span 
              style={{ 
                backgroundColor: !!this.state.moduleObj.visionModule ? 'green' : 'red',
                color: 'white',
              }}
            >
              { `${!!this.state.moduleObj.visionModule}` }
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

CompanyTableRow.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyTableRow;

