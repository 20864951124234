import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Actions
import { fetchUsersForState, deleteUsersForState } from 'actions/User';
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import UsersTable from 'components/UsersTable/container/UsersTable';

// API


// CSS
import styles from './UsersLayout.module.scss';

class UsersLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      resellerCompanyArr: [],
      displayFilterForm: false,
      userArr: [],
      loading: false,
    };
    this.handleUserEntry = this.handleUserEntry.bind(this);
  }

  componentDidMount() {
    const query = this.props.location.search;
    this.setState({ ...this.state, query });
    const queryProperties = Object.keys(query);
    if (queryProperties.length > 0) fetchUsersForState(decodeURIComponent(query[queryProperties[0]]), queryProperties[0]);
    Getters.getResellerCompanies().then((resellerCompanyArr) => {
      this.setState({ ...this.state, resellerCompanyArr });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.User !== this.props.User || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.User.fetched) {
      this.setState({ ...this.state, userArr: nextProps.User.users, loading: false });
    }
  }

  componentWillUnmount() {
    deleteUsersForState();
  }

  handleUserEntry(e, property) {
    if (property === 'outOfDateSoftware') {
      this.setState({ ...this.state, loading: true, userArr: [] });
      Getters.getOutdatedUsers('eldSoftwareVersion').then((userArr) => {
        this.setState({ ...this.state, loading: false, userArr });
      });
    } else {
      if (e.key === 'Enter') {
        this.setState({ ...this.state, loading: true, userArr: [] });
        fetchUsersForState(e.target.value, property);
      }
    }
  }

  render() {
    return (
      <div className={styles.usersLayout}>
        <Title title="Users">
          <span className="inlineBlock">
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'firstName')} placeholder="Enter User First Name" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'username')} placeholder="Enter User Username" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'belongsToCompany')} placeholder="Enter Company" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'dotNumber')} placeholder="Enter DOT Number" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'email')} placeholder="Enter User Email" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'deviceIMEI')} placeholder="Enter Device IMEI" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(event) => this.handleUserEntry(event, 'iccid')} placeholder="Enter Sim ICCID" />
            <button className="buttonDefault" onClick={() => this.handleUserEntry(null, 'outOfDateSoftware')} >Software Outdated</button>
          </span>
        </Title>
        <UsersTable
          fetched={!this.state.loading} // pass it User state to get action state
          resellerCompanyArr={this.state.resellerCompanyArr}
          users={this.state.userArr}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    User,
  };
};

UsersLayout.propTypes = {
  User: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UsersLayout);
