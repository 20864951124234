import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Modal } from 'react-bootstrap';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

function LoadingModal(props) {
  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Loading</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!props.finished &&
          <div>
            Loading...
            <LoadingIcon />
          </div>
        }
        {props.finished &&
          <div>
            <div>Finished!</div>
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <button className="closeButton" onClick={props.handleClose}>Close</button>
      </Modal.Footer>

    </Modal>
  );
}

LoadingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
};

export default LoadingModal;
