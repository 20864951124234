import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { TextField } from 'material-ui';

import * as ParseAPI from 'api/Parse';
import * as Setters from 'api/Setters';

// Components
// styles
import styles from './SendELDIssuePDFModal.module.scss';

class SendELDIssuePDFModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      accountInfo: {
        email: '',
        driverEmail: '',
      },
    };
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state, loading: false };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  handleSend() {
    this.setState({ ...this.state, loading: true }, () => {
      if (!this.state.accountInfo.email) {
        this.setState({ ...this.state, error: 'email needs to be entered' });
        return;
      }
      console.log(this.props.driver.id, this.state.accountInfo.email, this.state.accountInfo.driverEmail);
      Setters.sendELDIssuePdf(this.props.driver.id, this.state.accountInfo.email, this.state.accountInfo.driverEmail).then(() => {
        this.setState({ ...this.state, error: '', loading: false }, () => {
          this.props.handleClose();
        });
      }, (error) => {
        this.setState({ ...this.state, error: error.message, loading: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send ELD Issue PDF to driver</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              Enter the email to send the PDF to:
            </div>
            <div className={styles.inputs}>
              <TextField
                floatingLabelText="email"
                onChange={(event, newValue) => this.handleChangeInfo('email', newValue)}
                value={this.state.accountInfo.email}
              />
              <br />
              <TextField
                floatingLabelText="driver email (optional)"
                onChange={(event, newValue) => this.handleChangeInfo('driverEmail', newValue)}
                value={this.state.accountInfo.driverEmail}
              />
            </div>
            <br />
            <br />
            <div>
              {this.state.error &&
                <div className={styles.error}>
                  {this.state.error}
                  <br />
                  <br />
                </div>
              }
            </div>
            <div className={styles.selection}>
              <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose}>Cancel</button>
              <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleSend} disabled={this.state.loading}>Send PDF</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

SendELDIssuePDFModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default SendELDIssuePDFModal;
