import PropTypes from 'prop-types';
import React from 'react';

// Actions
import { requireAuth } from '../../actions/Auth';

// Components
import Header from 'components/Header/container/Header';
// CSS
import styles from './InAppLayout.module.scss';

class InAppLayout extends React.Component {
  componentDidMount() {
    document.title = 'Support Tool - Switchboard';

    requireAuth();
  }

  render() {
    return (
      <div>
        <Header pageLocation={this.props.location} />
        <div className={styles.inAppContent}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

InAppLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default InAppLayout;
