import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  notifications: [],
  unreadCount: 0,
  sinchClient: {},
  panelOpen: false,
  subscription: {},
};

function addNotification(stateNotifications, notificationObj) {
  const newNotifications = stateNotifications;
  newNotifications.unshift(notificationObj);
  return newNotifications;
}

function notificationRead(stateNotifications, notificationObj) {
  const newStateNotifications = stateNotifications;
  const index = Helpers.findIndexOfObjArr(stateNotifications, 'timeSinceEpoch', notificationObj.timeSinceEpoch);
  newStateNotifications[index] = notificationObj;
  return newStateNotifications;
}

function getUnreadCount(notificationArr) {
  const notificationArrLen = notificationArr.length;
  let unreadCount = 0;
  for (let i = 0; i < notificationArrLen; i++) {
    if (!notificationArr[i].read) {
      unreadCount++;
    }
  }
  return unreadCount;
}

export function Notification(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.NOTIFICATION_SET_READ:
      return {
        ...state,
        status: action.type,
        unreadCount: state.unreadCount - 1,
        notifications: notificationRead(state.notifications, action.notificationObject),
      };
    case ActionConstants.NOTIFICATION_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscription: action.subscription,
      };
    case ActionConstants.NOTIFICATION_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscription: {},
      };
    case ActionConstants.SINCH_TERMINATE:
      return {
        ...state,
        status: action.type,
        sinchClient: {},
      };
    case ActionConstants.SINCH_START_INPROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ActionConstants.SINCH_START_SUCCESS:
      return {
        ...state,
        status: action.type,
        sinchClient: action.sinchClient,
      };
    case ActionConstants.SINCH_START_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.FETCH_NOTIFICATIONS_INPROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ActionConstants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        status: action.type,
        unreadCount: getUnreadCount(action.notifications),
        notifications: action.notifications,
        error: undefined,
      };
    case ActionConstants.FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.NOTIFICATION_RECEIVE:
      return {
        ...state,
        status: action.type,
        unreadCount: state.unreadCount + 1,
        notifications: addNotification(state.notifications, action.notification),
      };
    case ActionConstants.NOTIFICATION_PANEL_TOGGLE:
      return {
        ...state,
        status: action.type,
        panelOpen: !state.panelOpen,
      };
    default:
      return state;
  }
}
