import * as ActionConstants from 'actions/ActionConstants';

export function Dispatcher(state = {}, action) {
  switch (action.type) {
    case ActionConstants.FETCH_DISPATCHER_INPROGRESS:
      return {
        ...state,
        status: action.type,
        dispatcher: {},
        error: undefined,
      };
    case ActionConstants.FETCH_DISPATCHER_SUCCESS:
      return {
        ...state,
        status: action.type,
        dispatcher: action.dispatcher,
      };
    case ActionConstants.FETCH_DISPATCHER_ERROR:
      return {
        ...state,
        status: action.type,
        dispatcher: {},
        error: action.error,
      };
    default:
      return state;
  }
}
