import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// Actions
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import SupportReportTable from 'components/SupportReportTable/container/SupportReportTable';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import styles from './SupportReportLayout.module.scss';

class UsersLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [
        { property: 'username', value: '' },
        { property: 'company', value: '' },
        { property: 'startDate', value: moment().startOf('week').toDate() },
        { property: 'endDate', value: moment().toDate() },
        { property: 'partner', value: '' },
        { property: 'solved', value: '' },
      ],
      supportReportArr: [],
      resellerCompanyArr: [],
      loading: false,
      generatingCsv: false,
    };
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.generateCsv = this.generateCsv.bind(this);
  }

  componentDidMount() {
    if (this.props.location.query && this.props.location.query.companyId) {
      const filterArr = [];
      filterArr.push({ property: 'companyId', value: this.props.location.query.companyId });
      this.setState({ ...this.state, loading: true });
      Getters.getSupportReports(filterArr).then((supportReportArr) => {
        this.setState({ ...this.state, supportReportArr, loading: false });
      });
    } else {
      this.handleFilter();
    }
  }

  handleChangeFilter(property, value) {
    const newState = { ...this.state };
    for (let i = 0; i < newState.filter.length; i++) {
      const subFilter = newState.filter[i];
      if (subFilter.property === property) {
        subFilter.value = value;
        break;
      }
    }
    this.setState(newState);
  }

  handleFilter(e) {
    const newState = { ...this.state, loading: true };
    for (let i = 0; i < newState.filter.length; i++) {
      const subFilter = newState.filter[i];
      if (subFilter.property === 'startDate') {
        subFilter.value = moment(subFilter.value).startOf('day').toDate();
      } else if (subFilter.property === 'endDate') {
        subFilter.value = moment(subFilter.value).endOf('day').toDate();
      } else if (subFilter.property === 'solved') {
        if (subFilter.value == 'true' || subFilter.value == true) subFilter.value = true;
        else if (subFilter.value == 'false' || subFilter.value == false) subFilter.value = false;
        else subFilter.value = null;
      }
    }
    this.setState(newState, () => {
      try {
        if ((e && e.key === 'Enter') || !e) {
          Getters.getSupportReports(this.state.filter).then((supportReportArr) => {
            this.setState({ ...this.state, supportReportArr, loading: false });
          });
        }
      } catch (error) {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      }
    });
  }

  generateCsv() {
    this.setState({ ...this.state, generateCsv: true });
    let csvContent = 'data:attachment/csv;charset=utf-8,' + 
    encodeURIComponent(
      'Date,User,Company,Issue,Solution,Status,Resolve Date\n' +
      this.state.supportReportArr.map(supportReport => {
        const date = moment(supportReport.get('createdAt')).format('lll')
        const userFullName = supportReport.get('user') ? `${supportReport.get('user') && supportReport.get('user').get('firstName')} ${supportReport.get('user') && supportReport.get('user').get('lastName')}` : '';
        const company = supportReport.get('company') ? `${supportReport.get('company') && supportReport.get('company').get('name')}` : '';
        const problem = supportReport.get('problem') ? `${supportReport.get('problem')}` : '';
        const solution = supportReport.get('solution') ? `${supportReport.get('solution')}` : '';
        const status = supportReport.get('solved') ? `Resolved` : 'Pending';
        const resolveDate = supportReport.get('resolveDate') ? moment(supportReport.get('resolveDate')).format('lll') : '';
        const rowArr = [];
        rowArr.push(`"${date}"`);
        rowArr.push(`"${userFullName}"`);
        rowArr.push(`"${company}"`);
        rowArr.push(`"${problem.replace('\n', '')}"`);
        rowArr.push(`"${solution.replace('\n', '')}"`);
        rowArr.push(`"${status}"`);
        rowArr.push(`"${resolveDate}"`);
        return rowArr.join(',');
      }).join('\n')
    );

    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    let filterStringArr = this.state.filter.map((filterObj) => filterObj.value);
    const filterString = `${filterStringArr.join('_')}.csv`;
    link.setAttribute('download', filterString);
    document.body.appendChild(link); // Required for FF
    link.click();
  }


  render() {
    let momentStartDate;
    let momentEndDate;
    for (let i = 0; i < this.state.filter.length; i++) {
      const subFilter = this.state.filter[i];
      if (subFilter.property === 'startDate') {
        momentStartDate = subFilter.value && moment(subFilter.value);
      } else if (subFilter.property === 'endDate') {
        momentEndDate = subFilter.value && moment(subFilter.value);
      } 
    }

    return (
      <div className={styles.container}>
        <Title title="Support Report">
          <button 
            className="buttonDefault greenButton" 
            onClick={() => this.generateCsv()} 
            disabled={this.state.generatingCsv}
          >
            Print Report
          </button>
        </Title>
        <div className="inlineBlock">
          <DatePicker
            className="input"
            dateFormat="ll"
            selected={momentStartDate}
            placeholderText="Start Date"
            onChange={(date) => { this.handleChangeFilter('startDate', date.toDate()); this.handleFilter();}}
          />
          <DatePicker
            className="input"
            dateFormat="ll"
            selected={momentEndDate}
            placeholderText="End Date"
            onChange={(date) => { this.handleChangeFilter('endDate', date.toDate()); this.handleFilter(); }}
          />
          <input type="text" 
            className="buttonSpacedRight" 
            value={this.state.filter.username}
            onChange={(e) => this.handleChangeFilter('username', e.target.value)}
            onKeyPress={(event) => this.handleFilter(event)} 
            placeholder="Enter Username" 
          />
          <input type="text" 
            className="buttonSpacedRight" 
            value={this.state.filter.company}
            onChange={(e) => this.handleChangeFilter('company', e.target.value)}
            onKeyPress={(event) => this.handleFilter(event)} 
            placeholder="Enter Company"
          />
          { this.state.resellerCompanyArr.length > 0 &&
            <select value={this.state.filter.partner} onChange={(e) => { this.handleChangeFilter('partner', e.target.value); this.handleFilter(); }}>
              <option>Select Partner</option>
              { this.state.resellerCompanyArr.map((resellerCompany) => 
                <option key={resellerCompany.id} value={resellerCompany.get('promoCode')}>{ resellerCompany.get('name') }</option>
              )}
            </select>
          }
          &nbsp;
          <select value={this.state.filter.solved} onChange={(e) => { this.handleChangeFilter('solved', e.target.value); this.handleFilter(); }}>
            <option value={null}>All Reports</option>
            <option value={true}>Solved</option>
            <option value={false}>Unresolved</option>
          </select>
        </div>
        <SupportReportTable
          fetched={!this.state.loading}
          supportReportArr={this.state.supportReportArr}
        />
      </div>
    );
  }
}

export default UsersLayout;
