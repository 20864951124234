import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// CSS
import styles from './TicketLayout.module.scss';

class TicketLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outOfOperationsLetter: {
        email: '',
        companyName: '',
        vehicleUnitId: '',
        driverName: '',
        loading: false,
      },
      displayFilterForm: false,
    };
    this.sendOutOfOperationsLetter = this.sendOutOfOperationsLetter.bind(this);
  }

  componentDidMount() {
  }

  sendOutOfOperationsLetter() {
    this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, loading: true } });
    Setters.sendELDIssuePdfOffline(
      this.state.outOfOperationsLetter.email,
      this.state.outOfOperationsLetter.companyName,
      this.state.outOfOperationsLetter.vehicleUnitId,
      this.state.outOfOperationsLetter.driverName,
    ).then((pdfData) => {
      console.log(pdfData);

      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.download = `Switchboard Issue Notice for ${this.state.outOfOperationsLetter.driverName} - ${moment().format('MMM DD, YYYY')}.pdf`;
      const blob = new Blob([pdfData.data], {
        type: 'application/pdf',
      });
      const url = window.URL;
      const downloadUrl = url.createObjectURL(blob);
      downloadLink.href = downloadUrl;
      downloadLink.click();
      this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, loading: false } });
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <div>
          <b>Generate & Download Out of Operation Letter</b>
          <br />
          <table>
            <tr>
              <td>Driver Name: &nbsp;</td>
              <td>
                <input
                  value={this.state.outOfOperationsLetter.driverName}
                  onChange={(e) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, driverName: Helpers.toTitleCase(e.target.value) } })}
                />
              </td>
            </tr>
            <tr>
              <td>Company Name: &nbsp;</td>
              <td>
                <input
                  value={this.state.outOfOperationsLetter.companyName}
                  onChange={(e) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, companyName: Helpers.capitalize(e.target.value) } })}
                />
              </td>
            </tr>
            <tr>
              <td>Vehicle Unit ID: &nbsp;</td>
              <td>
                <input
                  value={this.state.outOfOperationsLetter.vehicleUnitId}
                  onChange={(e) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, vehicleUnitId: e.target.value } })}
                />
              </td>
            </tr>
            {/* <tr>
              <td>Email Address: &nbsp;</td>
              <td>
                <input
                  value={this.state.outOfOperationsLetter.email}
                  onChange={(e) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, email: e.target.value } })}
                />
              </td>
            </tr> */}
          </table>
          {/* Driver Name: &nbsp;
          <input 
            value={this.state.outOfOperationsLetter.driverName} 
            onChange={(e, value) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, driverName: value }})}
          />
          <br />
          Company Name: &nbsp;
          <input 
            value={this.state.outOfOperationsLetter.companyName} 
            onChange={(e, value) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, companyName: value }})}
          />
          <br />
          Vehicle Unit ID: &nbsp;
          <input 
            value={this.state.outOfOperationsLetter.vehicleUnitId} 
            onChange={(e, value) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, vehicleUnitId: value }})}
          />
          <br />
          Email Address: &nbsp;
          <input 
            value={this.state.outOfOperationsLetter.email} 
            onChange={(e, value) => this.setState({ ...this.state, outOfOperationsLetter: { ...this.state.outOfOperationsLetter, email: value }})}
          />
          <br /> */}
          <button
            className="buttonDefault"
            onClick={() => this.sendOutOfOperationsLetter()}
            disabled={
              // !this.state.outOfOperationsLetter.email ||
              // Helpers.isInvalidEmailFormat(this.state.outOfOperationsLetter.email) ||
              !this.state.outOfOperationsLetter.driverName ||
              !this.state.outOfOperationsLetter.companyName ||
              !this.state.outOfOperationsLetter.vehicleUnitId ||
              this.state.outOfOperationsLetter.loading
            }
          >Download Letter PDF</button>
        </div>
      </div>
    );
  }
}

TicketLayout.propTypes = {
};

export default TicketLayout;
