import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'router';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import CompanyTableRow from 'components/CompaniesTable/container/CompanyTableRow';

// CSS
import styles from './CompaniesTable.module.scss';

class CompaniesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: true,
    };
    this.nameSort = this.nameSort.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.companies !== this.props.companies || nextProps.fetched !== this.props.fetched) {
      return true;
    }
    return false;
  }

  nameSort(company, nextCompany) {
    const comparatorA = company.name.toLowerCase();
    const comparatorB = nextCompany.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Company Name</th>
              {
                // <th>Admin</th>
                // <th>Number of Drivers</th>
                // <th>Number of JobLinks</th>
              }
              <th />
            </tr>
          </thead>
          <tbody>
            { !this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            { this.props.fetched && this.props.companies.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Companies to Display</td>
              </tr>
            }
            { this.props.fetched && this.props.companies.length > 0 &&
              this.props.companies.map((company) =>
                <CompanyTableRow key={company.id} company={company} />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

CompaniesTable.propTypes = {
  companies: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default CompaniesTable;

