import React from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';

// Components
import Title from 'components/LayoutTitle/view/Title';

// CSS
import styles from './HubspotLayout.module.scss';

class HubspotLayout extends React.Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(asdf) {
    // get all contacts
    // for each contact, create the company for them
    // Copy over properties
    // Copy over engagements
    axios.post('/hubspot/asdf').then((response) => {
      console.log(response);
    });
  }

  render() {
    return (
      <div className={styles.HubspotLayout}>
        <Title title="Hubspot" />
        <Button onClick={() => this.clickHandler('hi')}>hi Thar</Button>
      </div>
    );
  }
}

export default HubspotLayout;
