import axios from 'axios';
import * as ActionConstants from './ActionConstants';
import * as ParseAPI from 'api/Parse';
import Parse from 'parse';
import store from 'store';

import { fetchUserGlobals } from 'api/Getters';
import { fetchingUsers, fetchUsersSuccess, fetchUserError } from './User';
import { fetchingCompanies, fetchCompanySuccess, fetchCompanyError } from './Company';
import { fetchingAdmin, fetchAdminSuccess, fetchAdminError } from './Admin';
import { fetchingDispatcher, fetchDispatcherSuccess, fetchDispatcherError } from './Dispatcher';
import { fetchingSafetyOfficer, fetchSafetyOfficerSuccess, fetchSafetyOfficerError } from './SafetyOfficer';

function fetchingUserGlobals(promises) {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_INPROGRESS,
    promises,
  };
}

function fetchUserGlobalsSuccess() {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_SUCCESS,
  };
}

function fetchUserGlobalsError(error) {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_ERROR,
    error,
  };
}

export function fetchAndLoadUserGlobalsForState() {
  store.dispatch(fetchingUserGlobals());
  store.dispatch(fetchingUsers());
  store.dispatch(fetchingCompanies());
  store.dispatch(fetchingAdmin());
  store.dispatch(fetchingDispatcher());
  store.dispatch(fetchingSafetyOfficer());
  return fetchUserGlobals().then(
    (results) => {
      results.splice(1, 0, results[0].get('belongsToCompany'));
      store.dispatch(fetchUsersSuccess(results[0]));
      store.dispatch(fetchCompanySuccess(results[1]));
      store.dispatch(fetchAdminSuccess(results[2]));
      store.dispatch(fetchDispatcherSuccess(results[3]));
      store.dispatch(fetchSafetyOfficerSuccess(results[4]));
      store.dispatch(fetchUserGlobalsSuccess());
    },
    (error) => {
      store.dispatch(fetchUserError());
      store.dispatch(fetchCompanyError());
      store.dispatch(fetchAdminError());
      store.dispatch(fetchDispatcherError());
      store.dispatch(fetchUserGlobalsError(error));
      store.dispatch(fetchSafetyOfficerError());
    }
  );
}

export function initialize() {
  // Set parse keys and initialize
  const promise = new Promise((resolve, reject) => {
    axios.post('/switchboard/env').then((parseConfigObj) => {
      const { appId, jsKey, serverURL } = parseConfigObj.data;
      ParseAPI.start(appId, jsKey, serverURL);
      // ParseAPI.start(appId, jsKey, 'http://localhost:1337/parse');
      store.dispatch({ type: ActionConstants.INITIALIZE });
      // Logout to get rid of invalid Session Token error
      resolve();
    });
  });
  return promise;
}

export function checkUserGlobalsInState() {
  return new Promise((resolve) => {
    const checkStore = store.subscribe(() => {
      if (store.getState().Main.status === ActionConstants.FETCH_USER_GLOBALS_SUCCESS) {
        store.subscribe(checkStore); // unsubscribe
        resolve(true);
      }
    });
  });
}

export function clearState() {
  return {
    type: ActionConstants.CLEAR_STATE,
  };
}
