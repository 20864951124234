import PropTypes from 'prop-types';
import React from 'react';

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';

// Components
import LoadingModal from 'components/LoadingModal/container/LoadingModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './UserTableRow.module.scss';

class UserTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingModalShow: false,
      destroyFinished: false,
      showResetPasswordModal: false,
      showResetDataModal: false,
    };
    this.nameSort = this.nameSort.bind(this);
  }

  nameSort(user, nextUser) {
    const comparatorA = user.name.toLowerCase();
    const comparatorB = nextUser.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  render() {
    const user = this.props.user;
    return (
      <tr className={styles.userRow}>
        <td style={{ width: '15%' }}>
          { user.get('firstName') } { user.get('lastName')}
          { user.get('email') && 
            <div>{ user.get('email') }</div>
          }
          { user.get('phoneNumber') && 
            <div>{ user.get('phoneNumber') }</div>
          }
        </td>
        <td style={{ width: '15%' }}>
          { user.get('belongsToCompany') && user.get('belongsToCompany').get('name')}
          {this.props.resellerCompanyName &&
            <div>
              <b><u>Reseller:</u> {this.props.resellerCompanyName}</b>
            </div>
          }
          <div>
            {user.get('belongsToCompany') && <div>{user.get('belongsToCompany').get('dotNumber')}</div>}
          </div>
          <div>
            <b>Company Phone #: </b>
            {user.get('belongsToCompany') && user.get('belongsToCompany').get('phoneNumber') && <span>{user.get('belongsToCompany').get('phoneNumber')}</span>}
            {user.get('belongsToCompany') && !user.get('belongsToCompany').get('phoneNumber') && <span>Need to enter</span>}
          </div>
        </td>
        {
          // <td></td>
          // <td></td>
        }
        <td style={{ width: '70%' }}>
          <div className={styles.buttons}>
            { user.get('userType') && user.get('userType').indexOf(4) === -1 &&
              <span>
                { this.props.logInInProgress &&
                  <LoadingIcon />
                }
                { !this.props.logInInProgress &&
                  <button className="buttonDefault" onClick={() => this.props.logInAsCompany(this.props.user.id)}>Log In Cloud</button>
                }
              </span>
            }

            { user.get('userType') && user.get('userType').indexOf(4) === -1 &&
              <span>
                &nbsp;
                <button className="buttonDefault" onClick={() => this.props.showOfficeUserDetailsModal(this.props.user)}>View User Details</button>
              </span>
            }

            { user.get('userType') && user.get('userType').indexOf(4) !== -1 &&
              <span>
                &nbsp;
                <button className="buttonDefault" onClick={() => this.props.showDriverDetailsModal(this.props.user)}>View Driver Details</button>
              </span>
            }

            <span>
              &nbsp;
              <button className="buttonDefault" onClick={() => this.props.showSendUnableToReachEmailModal(user.get('email'), `${user.get('firstName')} ${user.get('lastName')}`)}>Send Unable to Reach Email</button>
            </span>
            <span>
              { this.props.creatingSupportReport &&
                <LoadingIcon />
              }
              &nbsp;
              { !this.props.creatingSupportReport &&
                <button className="buttonDefault yellowButton" onClick={() => this.props.createSupportReport(this.props.user)}>Create Support Report</button>
              }
            </span>
          </div>
        </td>
        <td className={styles.deleteButton}>
          <LoadingModal show={this.state.loadingModalShow} handleClose={() => { this.state.loadingModalShow = false; this.setState(this.state); }} finished={this.state.destroyFinished} />
        </td>
      </tr>
    );
  }
}

UserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  resellerCompanyName: PropTypes.string,
  showSendUnableToReachEmailModal: PropTypes.func.isRequired,
  showDriverDetailsModal: PropTypes.func.isRequired,
  logInInProgress: PropTypes.bool.isRequired,
  logInAsCompany: PropTypes.func.isRequired,
};

export default UserTableRow;
