import PropTypes from 'prop-types';
import React from 'react';

// CSS
import styles from './FeatureHeaderBar.module.scss';

class FeatureHeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.isActive = this.isActive.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pageLocation !== this.props.pageLocation) {
      return true;
    }
    return false;
  }

  isActive(nameOfRoute) {
    if (nameOfRoute === this.props.pageLocation) {
      return styles.active;
    }
    return '';
  }

  render() {
    const { tabStates } = this.props;
    return (
      <table className={styles.featureHeaderBar}>
        <tbody>
          <tr>
            {tabStates.ticket &&
              <td className={this.isActive('/ticket')} onClick={() => this.props.changeRoute('ticket')}>Ticket / Email</td>
            }
            {/* <td className={this.isActive('/companies')} onClick={() => this.props.changeRoute('companies')}>Companies</td> */}
            {tabStates.users &&
              <td className={this.isActive('/users')} onClick={() => this.props.changeRoute('users')}>Users</td>
            }
            {/* {tabStates.supportReport &&
              <td className={this.isActive('/supportReport')} onClick={() => this.props.changeRoute('supportReport')}>Support</td>
            } */}
            {tabStates.sims &&
              <td className={this.isActive('/sims')} onClick={() => this.props.changeRoute('sims')}>Sims</td>
            }
            {/* {tabStates.register &&
              <td className={this.isActive('/register')} onClick={() => this.props.changeRoute('register')}>Register</td>
            } */}
            {/* {tabStates.equipment &&
              <td className={this.isActive('/equipment')} onClick={() => this.props.changeRoute('equipment')}>Equipment</td>
            } */}
            {
              // <td className={this.isActive('subscriptions')} onClick={() => this.props.changeRoute('subscriptions')}>Subscriptions</td>
              // <td className={this.isActive('hubspot')} onClick={() => this.props.changeRoute('hubspot')}>Hubspot</td>
              // <td className={this.isActive('scripts')} onClick={() => this.props.changeRoute('scripts')}>Scripts</td>
            }
          </tr>
        </tbody>
      </table>
    );
  }
}

FeatureHeaderBar.propTypes = {
  tabStates: PropTypes.object,
  changeRoute: PropTypes.func.isRequired,
  pageLocation: PropTypes.string.isRequired,
};

export default FeatureHeaderBar;
