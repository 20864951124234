import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { TextField } from 'material-ui';

import * as ParseAPI from 'api/Parse';
import * as Setters from 'api/Setters';

// Components
// styles
import styles from './SendUnableToReachEmailModal.module.scss';

class SendUnableToReachEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      accountInfo: {
        email: props.email,
        name: props.name,
      },
    };
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, accountInfo: {
      ...this.state.accountInfo,
      email: nextProps.email,
      name: nextProps.name,
    }});
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state, loading: false };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  handleSend() {
    this.setState({ ...this.state, loading: true }, () => {
      if (!this.state.accountInfo.email) {
        this.setState({ ...this.state, error: 'email needs to be entered' });
        return;
      }
      Setters.sendUnableToReachEmail(this.state.accountInfo.email, this.state.accountInfo.name).then(() => {
        this.setState({ ...this.state, error: '', loading: false }, () => {
          this.props.handleClose();
        });
      }, (error) => {
        this.setState({ ...this.state, error: error.message, loading: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send "Unable to Reach" Email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              Enter the email to send to:
            </div>
            <div className={styles.inputs}>
              <TextField
                floatingLabelText="email"
                onChange={(event, newValue) => this.handleChangeInfo('email', newValue)}
                value={this.state.accountInfo.email}
              />
            </div>
            <br />
            <div className={styles.inputs}>
              <TextField
                floatingLabelText="Name of Person Called"
                onChange={(event, newValue) => this.handleChangeInfo('name', newValue)}
                value={this.state.accountInfo.name}
              />
            </div>
            <br />
            <br />
            <div>
              { this.state.error &&
                <div className={styles.error}>
                  {this.state.error}
                  <br />
                  <br />
                </div>
              }
            </div>
            <div className={styles.selection}>
              <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose}>Cancel</button>
              <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleSend} disabled={this.state.loading}>Send Email</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

SendUnableToReachEmailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default SendUnableToReachEmailModal;
