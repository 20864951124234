import PropTypes from 'prop-types';
import React from 'react';

// import { isInvalidEmailFormat } from 'api/Helpers';

// CSS
import styles from './LoginForm.module.scss';


function LoginForm(props) {
  return (
    <div className={styles.loginForm} onKeyPress={(e) => { if (e.key === 'Enter') props.handleSubmit(); }} >
      {!props.authErrorObject &&
        <div className={styles.authErrorMessage}>&nbsp;</div>
      }
      {props.authErrorObject &&
        <div className={styles.authErrorMessage}>{props.authErrorObject.message}</div>
      }

      <div className={styles.loginFormInputContainer}>
        <div>
          <input placeholder="Email Address" type="text" className={styles.loginInput} value={props.fields.email} onChange={(e) => props.handleFormChange('email', e.target.value)} />
        </div>
      </div>
      <div className={styles.loginFormInputContainer}>
        <div>
          <input placeholder="Password" type="password" className={styles.loginInput} value={props.fields.password} onChange={(e) => props.handleFormChange('password', e.target.value)} />
        </div>
      </div>
      <div className={styles.loginButtonContainer}>
        <button className="buttonDefault" type="submit" disabled={props.disableLogin} onClick={props.handleSubmit}>
          Login
        </button>
      </div>
      <div className={styles.forgotPassword}>
        {
          // props.fields.email ?
          // <a onClick={() => props.emailHandler(props.fields.email)}>Forgot Password?</a>
          // :
          // <a onClick={() => props.emailHandler()}>Forgot Password?</a>
        }
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  authErrorObject: PropTypes.object,
  emailHandler: PropTypes.func,
  fields: PropTypes.object,
  disableLogin: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default LoginForm;
