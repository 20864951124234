import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Actions

// Components
import ErrorModal from 'components/ErrorModal/ErrorModal';

// CSS
import styles from './EquipmentRow.css';

class VehicleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingWSB: false,
      error: ''
    };

    this.toggleBypass = this.toggleBypass.bind(this);
  }

  componentDidMount() {

  }

  toggleBypass(enabledBool) {
    const vehicleObj = this.props.vehicleObj;
    const companyObj = vehicleObj && vehicleObj.get('belongsToCompany');
    const subscriptionObj = companyObj && companyObj.get('subscription');
    const addressObj = companyObj && companyObj.get('address');
    this.setState({ ...this.state, loadingWSB: true }, () => {
      Setters.handleWSBVehicleSubscription(companyObj && companyObj.id, subscriptionObj && subscriptionObj.id, addressObj && addressObj.id, vehicleObj && vehicleObj.id, enabledBool).then(() => {
        this.setState({ ...this.state, loadingWSB: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loadingWSB: false, error: error.message });
      });
    });
  }

  render() {
    const vehicleObj = this.props.vehicleObj;
    const weighStationBypassVehicle = vehicleObj && vehicleObj.get('weighStationBypassVehicle');

    return (
      <tr className={styles.userRow}>
        <td>
          {vehicleObj.get('unitId')}
        </td>
        <td>
          {vehicleObj.get('belongsToCompany') && vehicleObj.get('belongsToCompany').get('name')}
        </td>
        <td>
          {vehicleObj.get('drivers') && vehicleObj.get('drivers').map((driverObj) => (
            <div>
              {driverObj.get('user_fullName')}
            </div>
          ))}
        </td>
        <td>
          {moment(vehicleObj.get('updatedAt')).format('LLL')}
        </td>
        <td>
          <div>
            {/* {weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') && weighStationBypassVehicle.get('isSubscriptionProduction') &&
              <button className="buttonDefault yellowButton" disabled={this.state.loadingWSB} onClick={() => this.toggleBypass(false)}>Disable Bypass</button>
            }
            {(!weighStationBypassVehicle || weighStationBypassVehicle.get('disabled') || !weighStationBypassVehicle.get('isSubscriptionProduction')) &&
              <button className="buttonDefault" disabled={this.state.loadingWSB} onClick={() => this.toggleBypass(true)}>Enable Bypass</button>
            }
            &nbsp;
            {vehicleObj.get('isHidden') && <button className="buttonDefault" onClick={() => this.props.hideUnhideEquipment(vehicleObj, 'Vehicle', false)}>Show Vehicle</button>}
            {!vehicleObj.get('isHidden') && <button className="buttonDefault yellowButton" onClick={() => this.props.hideUnhideEquipment(vehicleObj, 'Vehicle', true)}>Hide Vehicle</button>} */}
          </div>
        </td>
        {this.state.error &&
          <ErrorModal show={!!this.state.error} handleModalChoice={() => this.setState({ ...this.state, error: '' })} handleClose={() => this.setState({ ...this.state, error: '' })}>
            <div>{this.state.error}</div>
          </ErrorModal>
        }
      </tr>
    );
  }
}

VehicleRow.propTypes = {
  vehicleObj: PropTypes.object.isRequired,
  hideUnhideEquipment: PropTypes.func.isRequired,
};

export default VehicleRow;
