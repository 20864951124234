/* LES CONVENTIONS DES NOMMES OV DA CONSTUNTS (for the most part)
 * - FETCH: Fetch from database
 * - DELETE: Delete a STATE from the STORE
 * - DESTROY: Any other types of deletes
 */

// Admin.js
export const FETCH_ADMIN_INPROGRESS = 'FETCH_ADMIN_INPROGRESS';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_ERROR = 'FETCH_ADMIN_ERROR';

// Dispatcher.js
export const FETCH_DISPATCHER_INPROGRESS = 'FETCH_DISPATCHER_INPROGRESS';
export const FETCH_DISPATCHER_SUCCESS = 'FETCH_DISPATCHER_SUCCESS';
export const FETCH_DISPATCHER_ERROR = 'FETCH_DISPATCHER_ERROR';

// Subscription.js
export const INITIALIZE_STRIPE = 'INITIALIZE_STRIPE';
export const FETCH_SUBSCRIPTIONS_INPROGRESS = 'FETCH_SUBSCRIPTIONS_INPROGRESS';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';
export const DELETE_SUBSCRIPTIONS = 'DELETE_SUBSCRIPTIONS';
export const ADD_SUBSCRIPTIONS_SUCCESS = 'ADD_SUBSCRIPTIONS_SUCCESS';
export const ADD_SUBSCRIPTIONS_ERROR = 'ADD_SUBSCRIPTIONS_ERROR';
export const DESTROY_SUBSCRIPTIONS_SUCCESS = 'DESTROY_SUBSCRIPTIONS_SUCCESS';

// Company.js
export const FETCH_COMPANIES_INPROGRESS = 'FETCH_COMPANIES_INPROGRESS';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';
export const DELETE_COMPANIES = 'DELETE_COMPANIES';
export const DESTROY_COMPANIES_SUCCESS = 'DESTROY_COMPANIES_SUCCESS';

// DocumentLayout.js
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const CHANGE_DOCUMENTS_VIEW = 'CHANGE_DOCUMENTS_VIEW';

// DocumentCategory.js
export const FETCH_DOCUMENTCATEGORIES_INPROGRESS = 'FETCH_DOCUMENTCATEGORIES_INPROGRESS';
export const FETCH_DOCUMENTCATEGORIES_SUCCESS = 'FETCH_DOCUMENTCATEGORIES_SUCCESS';
export const FETCH_DOCUMENTCATEGORIES_ERROR = 'FETCH_DOCUMENTCATEGORIES_ERROR';

// Driver.js
export const FETCH_DRIVERS_INPROGRESS = 'FETCH_DRIVERS_INPROGRESS';
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS';
export const FETCH_DRIVERS_ERROR = 'FETCH_DRIVERS_ERROR';
export const ADD_DRIVER_INPROGRESS = 'ADD_DRIVER_INPROGRESS';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_ERROR = 'ADD_DRIVER_ERROR';
export const UPDATE_DRIVER_INPROGRESS = 'UPDATE_DRIVER_INPROGRESS';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_ERROR = 'UPDATE_DRIVER_ERROR';
export const DELETE_DRIVERS = 'DELETE_DRIVERS';

// JobAction.js
export const FETCH_JOBACTIONS_INPROGRESS = 'FETCH_JOBACTIONS_INPROGRESS';
export const FETCH_JOBACTIONS_SUCCESS = 'FETCH_JOBACTIONS_SUCCESS';
export const FETCH_JOBACTIONS_ERROR = 'FETCH_JOBACTIONS_ERROR';
export const ADD_JOBACTION_INPROGRESS = 'ADD_JOBACTION_INPROGRESS';
export const ADD_JOBACTION_SUCCESS = 'ADD_JOBACTION_SUCCESS';
export const ADD_JOBACTION_ERROR = 'ADD_JOBACTION_ERROR';
export const UPDATE_JOBACTION_INPROGRESS = 'UPDATE_JOBACTION_INPROGRESS';
export const UPDATE_JOBACTION_SUCCESS = 'UPDATE_JOBACTION_SUCCESS';
export const UPDATE_JOBACTION_ERROR = 'UPDATE_JOBACTION_ERROR';

// Login.js
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_INPROGRESS = 'LOGIN_INPROGRESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// Main.js
export const CLEAR_STATE = 'CLEAR_STATE';
export const FETCH_USER_GLOBALS_INPROGRESS = 'FETCH_USER_GLOBALS_INPROGRESS';
export const FETCH_USER_GLOBALS_SUCCESS = 'FETCH_USER_GLOBALS_SUCCESS';
export const FETCH_USER_GLOBALS_ERROR = 'FETCH_USER_GLOBALS_ERROR';
export const INITIALIZE = 'INITIALIZE';

// Mapbox.js
export const INITIALIZE_MAPBOX = 'INITIALIZE_MAPBOX';

// SafetyOfficer.js
export const FETCH_SAFETYOFFICER_INPROGRESS = 'FETCH_SAFETYOFFICER_INPROGRESS';
export const FETCH_SAFETYOFFICER_SUCCESS = 'FETCH_SAFETYOFFICER_SUCCESS';
export const FETCH_SAFETYOFFICER_ERROR = 'FETCH_SAFETYOFFICER_ERROR';

// Notification.js
export const SINCH_TERMINATE = 'SINCH_TERMINATE';
export const SINCH_START_INPROGRESS = 'SINCH_START_INPROGRESS';
export const SINCH_START_SUCCESS = 'SINCH_START_SUCCESS';
export const SINCH_START_ERROR = 'SINCH_START_ERROR';
export const FETCH_NOTIFICATIONS_INPROGRESS = 'FETCH_NOTIFICATIONS_INPROGRESS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
export const NOTIFICATION_SET_READ = 'NOTIFICATION_SET_READ';
export const NOTIFICATION_RECEIVE = 'NOTIFICATION_RECEIVE';
export const NOTIFICATION_PANEL_TOGGLE = 'NOTIFICATION_PANEL_TOGGLE';
export const NOTIFICATION_SUBSCRIBE_SUCCESS = 'NOTIFICATION_SUBSCRIBE_SUCCESS';
export const NOTIFICATION_UNSUBSCRIBE_SUCCESS = 'NOTIFICATION_UNSUBSCRIBE_SUCCESS';

// User.js
export const FETCH_USERS_INPROGRESS = 'FETCH_USERS_INPROGRESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const DELETE_USERS = 'DELETE_USERS';
export const REGISTER_USER_INPROGRESS = 'REGISTER_USER_INPROGRESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
