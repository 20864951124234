import React from 'react';

// Containers
import Login from 'components/Login/container/Login';

// CSS
import styles from './LoginLayout.module.scss';

// Assets
const colourLogo = require('assets/images/colour-logo-and-text.png');

export default function () {
  document.title = 'Login - Switchboard';
  return (
    <div className={styles.loginBackground}>
      <div className={styles.loginContent}>
        <img className={styles.loginLogo} role="presentation" src={colourLogo} />
        <Login />
      </div>
    </div>
  );
}
