import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Actions
import { fetchCompaniesForState, deleteCompaniesForState } from 'actions/Company';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import CompaniesTable from 'components/CompaniesTable/container/CompaniesTable';
import FilterForm from 'components/FilterForm/container/FilterForm';
// import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';

// CSS
import styles from './CompaniesLayout.module.scss';

class CompaniesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      displayFilterForm: false,
      tableView: 'allCompanies',
      activeCompanies: [],
      companies: [], // holds companies (all or active) based on filter dropdown view
    };
    this.handleCompanyEntry = this.handleCompanyEntry.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.Company !== this.props.Company || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.tableView === 'allCompanies') {
      const { Company } = nextProps;
      this.state.companies = [].concat(Company.companies);
    }
  }

  componentWillUnmount() {
    deleteCompaniesForState();
  }

  handleCompanyEntry(e) {
    if (e.key === 'Enter') {
      fetchCompaniesForState(e.target.value);
    }
  }

  render() {
    return (
      <div className={styles.companiesLayout}>
        <Title title="Companies">
          <span className="inlineBlock">
            <input type="text" className="buttonSpacedRight" onKeyPress={this.handleCompanyEntry} placeholder="Enter Company Name" />
          </span>
        </Title>
        <CompaniesTable
          tableView={this.state.tableView}
          fetched={this.props.Company.fetched} // pass it Company state to get action state
          companies={this.state.companies}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

CompaniesLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(CompaniesLayout);
