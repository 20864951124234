import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import router from 'router';
import { initialize } from 'actions/Main';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

require('styles/master.scss');
require('styles/_modal.scss');
require('styles/_table.scss');

initialize(false).then(() => {
  // Provider is a top-level component that wrapps our entire application, including
  // the Router. We pass it a reference to the store so we can use react-redux's
  // connect() method for Component Containers.
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider>
        {router}
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
  );
});
