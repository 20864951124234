import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

require('react-datepicker/dist/react-datepicker.css');

class FormControlDatePicker extends React.Component {
  render() {
    return (
      <ReactDatePicker
        placeholderText={this.props.placeholder}
        selected={this.props.value}
        {...this.props}
      />
    );
  }
}

FormControlDatePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
}

export default FormControlDatePicker;
